var translations_it = {
  "ACCEPTED": "Accettato",
  "ACCOUNT": "Account",
  "ACTIONS": "Azioni",
  "ACTIVE": "Attivo",
  "ACTIVITY": "Attività",
  "ADD": "Aggiungi",
  "ADMIN_NOTES": "Note amministrative",
  "ADVANCED_SEARCH": "Ricerca avanzata",
  "AGREEMENT": "Contratto",
  "AHEAD": "Avanti",
  "ALERTS": "Avvisi",
  "ALERTS_PLACEHOLDER": "Inserire qui eventuali avvisi da mostrare alla visualizzazione del profilo o all'assegnazione di un task",
  "AMOUNT": "Importo",
  "ASK_CONTINUE": "Continuare?",
  "BACK": "Indietro",
  "BARCODE": "Codice a barre",
  "BATCH": "Batch/Workflow",
  "BETA_BANNER": "Beta release di Door24",
  "BETA_BANNER_INFO": "Si prega di inviare segnalazione di eventuali anomalie di funzionamento documentate da screenshot a:",
  "CA": "CA",
  "CANCEL": "Annulla",
  "CANCEL_MOD": "Annulla modifiche",
  "CASHED": "Incassato",
  "CLEAR": "Cancella",
  "CLICK_HERE": "clicca qui",
  "CLOSE": "Chiudi",
  "COD": "Cod",
  "CONFIRM": "Conferma",
  "CONTRIBUTOR": "Collaboratore",
  "CONTRIBUTOR_SHORT": "Coll.re",
  "CONTRIBUTORS": "Collaboratori",
  "COOKIE_POLICY": "Cookie policy",
  "COOKIE_POLICY_URL": "https://www.landoor.com/privacy-policy/",
  "COST": "Costo",
  "COSTS": "Costi",
  "COUNT": "Conteggia",
  "COUNTER": "Contatore",
  "CREATE": "Crea",
  "CREATED_AT": "Data inserim. prev./ordine",
  "CREATE_ACCOUNT": "Crea o modifica account",
  "CURRENCY": "Valuta",
  "CURRENCY_ID": "ID Divisa",
  "CURRENT": "Attuale",
  "CUSTOMER": "Cliente",
  "CV": "Curriculum Vitae",
  "DATE": "Data",
  "DATE_EG": "eg. 01/01/2000",
  "DATE_FORMAT": "dd/MM/yyyy",
  "DATE_SELECT": "Seleziona una data",
  "DATE_TIME_FORMAT": "dd/MM/yyyy HH:mm",
  "DELETE": "Elimina",
  "DELETE_SUCCESS": "Eliminazione completata",
  "DEMO": "Demo",
  "DESTINATION": "Destinazione",
  "DETAIL": "Dettaglio",
  "DISASSOCIATE": "Disassocia",
  "DISCOUNT": "Sconto",
  "DISCOUNTS": "Sconti",
  "DOCUMENTS": "Documenti",
  "DOWNLOAD": "Scarica",
  "DOWNLOAD_CSV": "Esporta in formato CSV",
  "DOWNLOAD_CSV_BILLED": "Esporta fatturato in formato CSV",
  "DOWNLOAD_CSV_DONE_NOT_BILLED_TASKS": "Esporta i task non fatturati",
  "DOWNLOAD_JSON": "Esporta le ultime modifiche in JSON",
  "DOWNLOAD_REPORT": "Scarica report",
  "DOWNLOAD_REPORT_SUCCESS": "Report in fase di elaborazione, \nsarà inviato al tuo indirizzo email.",
  "DOWNLOAD_REQUEST": "Vuoi esportare la lista?\nL'operazione potrebbe richiedere alcuni minuti.",
  "DOWNLOAD_REQUEST_ALL_COLUMNS": "Abilita il cursore accanto a \"Mostra tutte le colonne della tabella\" per esportare tutte le colonne.",
  "DUPLICATE": "Duplica",
  "EMAIL": "Email",
  "EMAIL_FORCE_SEND_NOW": "Forza invio email immediato",
  "EMAIL_SENT_AT": "Email del",
  "EMAIL_SENT_TO": "Inviata a",
  "ENABLE": "Abilita",
  "ERROR": "Errore",
  "EXCHANGE_RATE": "Tasso di cambio",
  "EXIT": "Esci",
  "EXPAND": "Espandi",
  "EXPIR": "scad.",
  "EXPIRATION": "Scadenza",
  "EXPORT_EMAIL_SUCCESS_1": "Riceverai all'indirizzo email utilizzato per il login il link per scaricare l'esportazione.",
  "EXPORT_EMAIL_SUCCESS_2": "Se non lo ricevi entro 15 minuti, controlla la cartella SPAM oppure effettua una nuova esportazione.",
  "EXTERNAL_CODE": "Codice esterno",
  "FILTER": "Filtro",
  "FILTERS": "Filtri",
  "FOLLOW_UP_NOTES": "Note follow-up",
  "FROM": "Da",
  "GIFTS": "Omaggi",
  "GLOBAL": "Globale",
  "GOTO": "Vai",
  "GOTO_LIST": "Vai alla lista",
  "HARDWARE": "Hardware",
  "HI": "Ciao",
  "HOME": "Home",
  "IBAN": "IBAN",
  "INFO_196_03_LABEL": "INFORMATIVA EX D.LGS. 196/03 IN MATERIA DI PROTEZIONE DEI DATI PERSONALI",
  "INFO_196_03_MESSAGE": "Letta la nota informativa, esprimo il mio consenso al trattamento alla comunicazione dei miei dati personali a società che svolgono funzioni necessarie o strumentali all'operatività del Servizio che potranno, per le medesime finalità, anche trasmetterli a società terze. In mancanza del mio consenso il servizio non potrà essere erogato.",
  "INSERT": "Inserisci",
  "LABEL": "Etichetta",
  "LANGUAGES": "Lingue",
  "LIST": "Lista",
  "LOGIN": "Login",
  "MFA_CODE": "Codice",
  "MIN_3_CHARS": "minimo 3 caratteri",
  "MODIFY": "Modifica",
  "NEW": "Nuovo",
  "NEXT": "Successivo",
  "NO": "No",
  "NO_APPROVE": "Non approvare",
  "NONE": "Nessuno",
  "NOT_ACCEPTED": "Non accettato",
  "NOTE": "Note",
  "NOTE_ADMIN": "Note amministrative",
  "NO_DATE": "Nessuna data",
  "NO_LIST": "Nessun elemento nella lista",
  "NO_NOTE": "Nessuna nota",
  "NO_RESULTS": "Nessun risultato",
  "NO_RESULT_FOR": "Nessun risultato per",
  "NO_SELECTED": "Nessun elemento selezionato",
  "NUMBER": "Numero",
  "OPEN_LINK_TARGET_BLANK": "Apri in una nuova scheda",
  "OTHER": "Altro",
  "PARTIAL": "Parziale",
  "PASSWORD": "Password",
  "PREFIX": "Prefisso",
  "PREVIOUS": "Precedente",
  "PRICE": "Prezzo",
  "PRICES": "Prezzi",
  "PRIMARY": "Primario",
  "PRINT": "Stampa",
  "PRIVACY": "Privacy",
  "PRIVACY_POLICY": "Privacy policy",
  "PRIVACY_POLICY_URL": "https://www.landoor.com/privacy-policy/",
  "PROJECT": "Progetto",
  "PROJECTS": "Progetti",
  "QUANTITY": "Quantità",
  "QUANTITY_SHORT": "Qta",
  "RATE": "Tariffa",
  "READ": "Vedi",
  "REVENUE": "Fatturato",
  "SAVE": "Salva",
  "SAVE_REQUEST": "I dati inseriti non sono salvati. Salvare o annullare le modifiche prima di uscire",
  "SDI": "Codice SDI",
  "SEARCH": "Cerca",
  "SECTOR": "Settore",
  "SECTORS": "Settori",
  "SELECT": "Seleziona",
  "SELECT_ALL": "Seleziona tutto",
  "SELECT_CONTRIBUTOR": "Scegli collaboratore",
  "SELECT_CONTRIBUTORS": "Scegli collaboratori",
  "SELECT_FILE": "Carica file",
  "SENDING_DATE": "Data di invio",
  "SERVICE": "Servizio",
  "SERVICE_LEVEL_AGREEMENT": "Accordo sul livello del servizio",
  "SETTINGS": "Impostazioni",
  "SHOW_ALL": "Mostra tutto",
  "SHOW_LESS": "Mostra meno",
  "SHOW_MORE": "Mostrane di più",
  "SOFTWARE": "Software",
  "SOFTWARE_SHORT": "SW",
  "STATUS": "Stato",
  "STYLEGUIDE": "Styleguide",
  "SUFFIX": "Suffisso",
  "TASK": "Task",
  "TAX": "Imposta",
  "TAXABLE": "Imponibile",
  "TEMPLATE": "Template",
  "TERMS": "Termini e condizioni",
  "TERMS_URL": "https://www.landoor.com/termini-condizioni/",
  "THANKS": "Grazie",
  "TO": "A",
  "TO_BE_DEFINED": "Da definire",
  "TODAY": "Oggi",
  "TOTAL": "Totale",
  "TYPE": "Tipo",
  "UM": "UM",
  "UNAVAILABLE": "Non disponibile",
  "UNDO": "Annulla",
  "UNIT_PRICE": "Prezzo unitario",
  "UNSPECIFIED": "Non specificata",
  "UNTIL": "fino a",
  "UOM": "Unità di misura",
  "DESCRIPTION_TASK": "Descrizione task",
  "UPDATE": "Aggiorna",
  "UPDATED": "Aggiornato",
  "USER": "Utente",
  "USERS": "Utenti",
  "VAT": "IVA",
  "VAT_CODE": "Codice IVA",
  "VOICE": "Voce",
  "WEBSITE": "Sito web",
  "WEIGHT": "Peso",
  "YEAR": "Anno",
  "YES": "Sì",
  "WAITING": "In attesa",
  "admin": {
    "ADMINS": "Utenti interni",
    "ACCOUNT_ROLE": "Ruolo account Door24",
    "CREATE_ADMIN": "Crea un nuovo utente interno",
    "SELECT_ROLE": "Seleziona il ruolo"
  },
  "auth": {
    "ACCEPT_LICENCE": "Accetta le condizioni di licenza",
    "BACK_TO_LOGIN": "Torna al login",
    "FORCE_RESET_PASSWORD": "Hai superato il limite di tentativi consentito. Cambia la tua password per accedere di nuovo.",
    "FORGOTTEN_ASK": "Password dimenticata?",
    "FORGOT_INFO": "Inserisci il tuo indirizzo e-mail. Ti sarà inviato un messaggio con un link per creare una nuova password.",
    "LOGIN_ERROR": "Email o password errata",
    "MFA_LOGIN_ERROR": "Il codice è errato oppure scaduto",
    "MFA_LOGIN_MSG_1": "È stato inviato alla tua casella email il codice per completate il login, inseriscilo qui sotto.",
    "MFA_LOGIN_MSG_2": "Se non lo ricevi entro qualche minuto, controlla la cartella SPAM.",
    "MFA_LOGIN_MSG_3": "Per ricevere un nuovo codice, torna al login e ripeti la procedura.",
    "NEW_PASS_SENT": "Ti abbiamo inviato una nuova password. Riprova a effettuare l'accesso.",
    "NO_EMAIL_FOUND": "L'indirizzo inserito non corrisponde ad alcun utente registrato",
    "REMEMBER_PASSWORD": "Ricorda password",
    "SIGNUP": "Registrati",
    "SIGNUP_ERROR": "Impossibile completare la richiesta di registrazione",
    "SIGNUP_REQ_SENT": "Riceverai un'email per completare la richiesta di registrazione"
  },
  "company": {
    "ACQUISITION_SOURCE": "Fonte di acquisizione",
    "ACTIVE": "Azienda attiva",
    "ACTIVE_BTN": "Clicca per attivare",
    "ACTIVITY_MACROSECTOR": "Macrosettore di attività",
    "ACTIVITY_SECTOR": "Settore di attività",
    "AGENT_ALERT": "Avviso fornitore procacciatore",
    "AMOUNT_EXPIRED": "Importo scaduto",
    "ASSOCIATED": "Clienti Associati",
    "ASK_ACTIVE": "Vuoi attivare l'azienda?",
    "ASK_DEACTIVE": "Vuoi disattivare l'azienda?",
    "ASSOCIATE": "Associa",
    "ASSOCIATE_LIST_ALREADY_MSG": "Listino già presente. Vuoi aggiungerlo comunque?",
    "ASSOCIATE_LIST_MSG": "Associare il listino al cliente?",
    "ASSOCIATE_TO_COMPANY": "Associa a cliente esistente",
    "SHOW_COMPANY_DOCUMENTAL": "Visualizza nel documentale",
    "ASSOCIATION_ERROR": "Associazione fallita",
    "ASSOCIATION_SUCCESS": "Associazione effettuata",
    "BILLING": "Dati fatturazione",
    "BILLING_COMPANIES": "Dati di fatturazione",
    "BILLING_MESSAGE": "Seleziona dalla lista sotto per modificare l\'intestazione",
    "BUSINESS_NAME": "Ragione sociale",
    "BU_NAME": "Nome Business Unit",
    "CERTIFICATION": "Certificazione",
    "CERTIFICATIONS": "Certificazioni",
    "CERTIFICATIONS_OTHER": "Altre",
    "CERTIFICATIONS_OTHER_LABEL": "Inserire qui altre eventuali certificazioni",
    "CHECK_TRADOS_ERR": "I listini di questo cliente non prevedono fasce CAT",
    "COMPANY_HEADQUARTERS": "Sedi azienda",
    "CONDITIONS_CONFIRM": "Conferma condizioni",
    "CONDITIONS_CONFIRM_HELP": "Flaggare se il cliente deve confermare le Condizioni generali di fornitura (SLA, Service Level Agreement) a ogni caricamento lavoro o richiesta di preventivo. Non flaggare se il cliente ha già un ordine quadro.",
    "CREATE_COMPANY": "Crea una nuova azienda",
    "CUSTOMER": "Cliente",
    "CUSTOMERS": "Clienti",
    "CUSTOMIZING_SERVICES": "Personalizzazione servizi",
    "CUSTOM_TASKS": "Task personalizzati",
    "DEACTIVE_BTN": "Clicca per disattivare",
    "DELETE_24H_PROJECT": "Eliminare il progetto?",
    "DELETE_TEMPLATE_PROJECT": "Eliminare il progetto?",
    "DELETE_ACTIVITY": "Elimina attività",
    "DELETE_EMPTY_ACT": "Selezionare un macrosettore, oppure eliminare l'attività.",
    "DELETE_CUSTOM_SERVICE_MESSAGE": "Vuoi eliminare il servizio personalizzato?",
    "DELETE_LIST_MSG": "Eliminare il listino in modo permanente?",
    "DISABLE_EXPIRED_AMOUNT_ALERT": "Disabilita avviso insoluto",
    "DOC_MACROSECTOR": "Macrosettore documento",
    "DOC_SECTOR": "Settore documento",
    "DOC_TYPE": "Tipo documento",
    "EMAIL_CC": "Email CC",
    "EMAIL_CC_INFO": "Indirizzo email a cui saranno inviate tutte le comunicazioni in copia conoscenza",
    "EMAIL_INVOICE": "Email notifica fatturazione",
    "EMAIL_INVOICE_HELP": "Indirizzo email al quale si desidera inviare le fatture e chiederne l'approvazione",
    "EMAIL_INVOICE_PLACEHOLDER": "Indirizzo email al quale si desidera inviare le fatture",
    "EMPLOYEES_NUMBER": "Numero dipendenti",
    "EXPIRED_MESSAGE": "Il cliente ha un importo scaduto di euro",
    "FORM": "Dettaglio cliente",
    "FOUNDATION_YEAR": "Anno di fondazione",
    "FRAMEWORK_AGREEMENT": "Accordo quadro",
    "GOTO": "Vai all'azienda",
    "HEADQUARTER": "Divisione / reparto",
    "HEADQUARTERS": "Divisioni / reparti",
    "HEADQUARTERS_MESSAGE_DELETE": "Vuoi eliminare la sede operativa?",
    "HEADQUARTER_ID": "ID Sede",
    "HEADQUARTER_NAME": "Nome sede",
    "JOINT_VENTURE": "Joint venture",
    "LIST": "Listino",
    "LISTS": "Listini",
    "MAILING": "Mailing",
    "NO_EMAIL_LEGAL_ADDRESS": "La sede legale non ha un indirizzo email",
    "NO_HEADQUARTER": "Nessuna sede",
    "NOT_ACTIVE": "Azienda non attiva",
    "NO_SECTORS": "Nessun settore selezionabile",
    "OVERWRITE_LISTS_MSG": "Sovrascrivere il listino corrente?",
    "OWN": "Intest. fattura",
    "PAYMENT_CONDITIONS": "Condizioni di pagamento",
    "PAYMENT_MODE": "Modalità di pagamento",
    "PMS_NUMBER": "Numero Project Manager",
    "PROJECT_CODE": "Sigla commessa",
    "PUBLIC_AUTORITY": "Sono un ente pubblico",
    "REQUIRES_TRADOS_ERR": "Questo cliente richiede unità di misura con fasce di prezzo Trados. Impossibile completare l'operazione.",
    "SELECT_A_COMPANY": "Seleziona un cliente",
    "SERVICE_ALREADY_INSERT": "Servizio già inserito",
    "SET_DEFAULT_LIST": "Marca come predefinito",
    "SET_DEFAULT_LIST_MSG": "Marcare questo listino come predefinito?",
    "SPECIALIZATION_MACROSECTOR": "Macrosettore di specializzazione",
    "TASK": "Lavoro",
    "TASKS": "Lavori",
    "TASKS_CUSTOM_VALIDATION_ERROR": "Inserire almeno un task personalizzato per ogni servizio",
    "TRADOS_NOT_USED": "Questo cliente non usa Trados",
    "TRADOS_RANGE": "Fasce Trados",
    "TRADOS_RANGE_EDIT": "Modifica Fasce Trados",
    "UNRELIABLE": "Non usare/Inaffidabile",
    "UNRELIABLE_MESSAGE": "Il cliente è contrassegnato come \"Non usare/Inaffidabile\"",
    "USED_CONTRIBUTORS": "Collaboratori più usati",
    "ORDERLABEL": "Sigla per commessa",
    "ISO17100": "Applicabilità 17100"
  },
  "contacts": {
    "EMAIL": "Email",
    "EMAIL1": "Email 1",
    "EMAIL2": "Email 2",
    "EMAIL3": "Email 3",
    "EMAIL_HEADQUARTER": "Email sede",
    "ERROR_INSERT_FORM": "* Inserisci almeno un recapito valido",
    "FAX": "Fax",
    "PHONE": "Telefono",
    "PHONE_CELL": "Telefono cellulare",
    "PHONE_HEADQUARTER": "Telefono sede",
    "PHONE_HOME": "Telefono abitazione",
    "PHONE_SWITCHBOARD": "Telefono centralino",
    "PHONE_WORK": "Telefono ufficio",
    "ALT_PHONE": "Telefono alternativo",
    "ALT_PHONE_SHORT": "Tel. alternativo"
  },
  "contributors": {
    "ACTIVE": "Collaboratore attivo",
    "ADDED_INFO": "Informazioni aggiuntive",
    "ADD_MONOLINGUAL_SERVICES": "Aggiungi servizi monolingui",
    "ADD_SECTORS": "Aggiungi settori",
    "ADD_SERVICE": "Aggiungi un servizio",
    "ADD_SERVICES": "Aggiungi servizi",
    "ALL_FILES_UPLOADED_CONFIRM": "Hai caricato tutti i file? Se sì, il task sarà contrassegnato come eseguito",
    "AMOUNT": "Importo collaboratore",
    "ASK_ACTIVE": "Vuoi attivare il collaboratore?",
    "ASK_DEACTIVE": "Vuoi disattivare il collaboratore?",
    "ASSOCIATION": "Associazione professionale",
    "ASSOCIATIONS": "Associazioni professionali",
    "AVAILABILTY_FOLDER": "Disponibilità giornaliera in cartelle",
    "AVAILABILTY_HOUR": "Disponibilità giornaliera in ore",
    "AVAILABILTY_TREAT": "Disponibilità a trattare",
    "AVAILABILTY_WORD": "Disponibilità giornaliera in parole",
    "BILLING": "Fatturazione",
    "BLACKLISTED_WARNING": "Non è possibile aggiungere il collaboratore perché è nella blacklist del committente.",
    "BROCHURE": "Carica una brochure di presentazione",
    "CERTIFICATION": "Certificazione professionale",
    "CERTIFICATIONS": "Certificazioni professionali",
    "COMPLETE_PERC": "Completamento profilo",
    "COMPLETE_PERC_SHORT": "% profilo",
    "CONNECTION": "Connessione abituale",
    "CONTRIBUTOR": "Collaboratore",
    "CONTRIBUTORS": "Collaboratori",
    "CREATE_CONTRIBUTOR": "Crea un nuovo collaboratore",
    "CV_ERROR_INSERT": "* Caricare un file",
    "DELETE_FILE_MESSAGE": "Vuoi eliminare il file?",
    "DELETE_VOTE": "Vuoi eliminare la votazione?",
    "DETAIL": "Dettaglio collaboratore",
    "DETAIL_PL": "Dettaglio collaboratori",
    "DICTIONARY_INSTALLED": "Dizionari installati",
    "DICTIONARY_ONLINE": "Dizionari online",
    "DOCUMENT_TYPE_VOTE": "Voto per tipologia di documento",
    "DOCUMENTARY": "Documentale",
    "DOCUMENTARY_ACCESS": "Accesso al Documentale",
    "DOCUMENTARY_ACCESS_FOR_CUSTOMERS": "Clienti per cui il collaboratore è autorizzato:",
    "DOCUMENTARY_ADD_COMPANY": "Aggiungi cliente",
    "EDIT_TRADOS_WARNING": "Per utilizzare le fasce Trados per il collaboratore, lasciare vuoti i campi \"Quantità\" e \"Importo unitario\"",
    "EDUCATION": "Titolo di studio/occasione formativa",
    "EDUCATIONS": "Titoli di studio/occasioni formative",
    "EDUCATION_ERROR_INSERT": "* Inserisci almeno un titolo di studio",
    "EDUCATION_PLACE": "Presso",
    "EXPERIENCE": "Esperienza lavorativa / Funzione / Mansione",
    "EXPERIENCES": "Esperienze lavorative",
    "EXPERIENCE_PLACE": "Presso / per conto di",
    "FILTER_ISO17100": "Idoneo ISO 17100",
    "EXPORT_SPECIALIZATIONS_CSV": "Esporta valutazioni specializzazioni",
    "EXPORT_SPECIALIZATIONS_TOTALS_CSV": "Esporta totali valutazioni",
    "FROM_CAFE": "Da internet point/cafè",
    "FROM_HOME": "Da casa",
    "FROM_PERSONAL_OFFICE": "Da ufficio proprio",
    "FROM_THIRD_OFFICE": "Da ufficio di terzi",
    "FROM_WHERE_CONNECT": "Da dove mi connetto",
    "GENERAL_QUALIFICATION": "Qualificazione generale",
    "GENERAL_VOTE": "Voto generale",
    "HAS_AGREEMENT": "Contratto caricato",
    "HIDE_PRICES": "Nascondi tariffe",
    "HEADQUARTER_NAME": "Ragione sociale / BU locale",
    "HOLIDAYS": "Out of office",
    "IBAN": "IBAN",
    "IBAN_SWIFT": "Swift Code",
    "INCOME": "Tipo di reddito",
    "INPS": "Rivalsa INPS %",
    "INSERT_VOTE": "Inserisci un voto da 1 a 10",
    "INTERPRETERS_MACROSECTOR": "Interpretariato",
    "ISO_17100": "Quale dei seguenti livelli di competenza hai raggiunto (ai fini della idoneità 17100)?",
    "ISO_17100_NOT_REACHED": "non ha raggiunto un livello di competenza sufficiente ai fini della idoneità 17100",
    "LANG": "Lingua di visualizzazione",
    "LOCATION": "Località",
    "LOCATION_DEKSTOP": "Desktop",
    "LOCATION_LAPTOP": "Laptop",
    "LOCATION_OS": "Sistema operativo postazione abituale",
    "LOCATION_OTHER": "Altro",
    "LOCATION_PERSONAL_NAME": "Nome della postazione abituale",
    "LOCATION_PERSONAL_TYPE": "Tipo e modello della postazione abituale",
    "LSP": "Persona giuridica",
    "LSP_ABOUT": "Scheda informativa",
    "MACROSECTOR": "Macrosettore",
    "MACROSECTOR_VOTE": "Voto per macrosettore",
    "MAX_COST": "Costo massimo",
    "NETWORK": "Network",
    "NETWORK_UOM": "Ulteriore unità di misura",
    "NEW_WORD_PRICE": "Tariffa Parola New",
    "NO_AGREEMENT": "Contratto non caricato",
    "NO_SELECTED_CONTRIBUTORS_MSG": "Non hai selezionato nessun collaboratore",
    "NOT_ACTIVE": "Collaboratore non attivo",
    "NOT_NETWORK": "Non network",
    "NO_CERTIFICATION": "Nessuna certificazione selezionabile",
    "NO_EDUCATION": "Nessun titolo",
    "NO_HOLIDAY_SET": "Non ci sono ferie impostate. Premi il tasto \"+\" per inserirne.",
    "NO_SOFTWARE": "Non hai alcun servizio che preveda l'uso di software",
    "NUMBER_DAYS": "Numero giorni",
    "ON_HOLIDAY_UNTIL": "Out of office fino al",
    "OS": "Sistema Operativo",
    "PAYMENT": "Condizioni di pagamento",
    "PROBLEMS_SW_HD": "Problemi principali e carenze relative agli strumenti software/hardware a disposizione",
    "PROGRAMS_IN_COMPANY": "Programmi di uso corrente quando in azienda",
    "PROGRAMS_OTHERS": "Altri programmi",
    "QUALIFIED_DATE": "Data qualificazione",
    "QUALIFIED_DATE_LATER": "Data qualificazione successiva a",
    "RATING": "Valutazione",
    "RATINGS_HISTORY": "Storico valutazioni",
    "RDA": "RDA %",
    "RDA_TAXABLE": "Imponibile RDA %",
    "SCORE": "Punteggio",
    "SCORE_SHORT": "Punt.",
    "SCORE_INITIAL": "Punteggio iniziale",
    "SCORE_UPDATED": "Punteggio aggiornato",
    "SEARCH": "Ricerca collaboratore",
    "SECTOR": "Settore",
    "SECTOR_ALREADY_INSERT": "Settore già inserito",
    "SELECT_A_CONTRIBUTOR_FIRST_1": "Seleziona prima un collaboratore",
    "SELECT_A_CONTRIBUTOR_FIRST_2": "utilizzando il bottone \"Task\" nell'anagrafica di un collaboratore",
    "SELECT_NATIVE_MESSAGE": "Seleziona una lingua madre",
    "SELECT_SECTOR_MESSAGE": "Seleziona un settore",
    "SELECT_SOURCE_LANGS": "Seleziona le lingue madri",
    "SELECT_TASK_MESSAGE": "Seleziona un servizio",
    "SEND_UPDATE_REQUEST_1": "Invia una richiesta di",
    "SEND_UPDATE_REQUEST_2": "aggiornamento profilo",
    "SEND_UPDATE_REQUEST_CONFIRM": "Vuoi inviare una richiesta di aggiornamento profilo?",
    "SEND_UPDATE_REQUESTS_CONFIRM": "Vuoi inviare una richiesta di aggiornamento profilo ad ogni utente selezionato?",
    "SEND_UPDATE_REQUEST_SUCCESS": "Richiesta inviata correttamente",
    "SERVICES": "Servizi",
    "SERVICES_BILINGUAL": "Servizi bilingue",
    "SERVICES_MONOLINGUAL": "Servizi monolingue",
    "SERVICES_OFFERED": "Servizi offerti",
    "SERVICES_OTHER": "Altri servizi",
    "SPECIALIZATIONS": "Specializzazioni",
    "SPECIALIZATIONS_SHORT": "Spec.",
    "SPECIFY": "Specificare",
    "SPEED_DOWNLOAD": "Velocità connessione download (Mbps)",
    "SPEED_TEST_1": "Velocità reale di collegamento: collegarsi al sito",
    "SPEED_TEST_2": "e riportare i valori",
    "SPEED_UPLOAD": "Velocità connessione upload (Mbps)",
    "STATUS_ACTIVE": "Disponibile",
    "STATUS_APPROVED": "Approvato",
    "STATUS_DELETED": "Eliminato",
    "STATUS_NOT_APPROVED": "Non approvato",
    "STATUS_POTENTIAL": "Potenziale",
    "STATUS_QUALIFIED": "Qualificato",
    "SUGGESTIONS_NOTE": "Suggerimenti/note",
    "SUPPLIER": "Fornitore",
    "TASK": "Task",
    "TASK_ALREADY_INSERT": "Servizio già inserito",
    "TAX_CODE": "Codice tributo",
    "TOOLS": "Strumenti di lavoro",
    "TRADOS_RANGES": "Fasce Trados Collaboratore",
    "TRADOS_LOAD_RANGES": "Precompila tariffe Collaboratore",
    "TRAINING_OTHER": "Altra formazione IT desiderata",
    "TRAINING_SW_INFORMATIC": "Per quali software generici desideri ricevere una formazione?",
    "TRAINING_SW_LANGUAGE": "Per quali software linguistici desideri ricevere una formazione?",
    "TRANSLATION_COMBOS": "Combinazioni traduzione",
    "UNIT_AMOUNT": "Importo unitario",
    "UOM": "Unità di misura collaboratore",
    "UOM_TYPE": "Tipo costo",
    "VAT": "Aliquota IVA",
    "VIEW_INFO": "Leggi l'informativa",
    "WHEN_IN_COMPANY": "Quando in azienda",
    "WHEN_REMOTE_CONNECTION": "Quando in collegamento remoto con Door24",
    "LAST_ACCESS_DATE": "Data ultimo accesso",
    "TRASLATE_COMBINATION": "Comb. Trad.",
    "PRICE": "Parola",
    "utype": {
      "CONTRIBUTOR": "Collaboratore",
      "DTP": "DTP",
      "HOSTESS": "Hostess",
      "SUPPLIER": "Fornitore"
    }
  },
  "create_account": {
    "IGNORED_PASSWORD_MSG": "La password inserita verrà ignorata se non si modifica l'indirizzo email.",
    "LOGOUT_TO_EDIT_PASSWORD_MSG": "Per modificare solo la password del tuo account, effettua il logout e clicca su \"Password dimenticata?\" nella pagina di login.",
    "EDIT_PASSWORD_ANOTHER_ACCOUNT_MSG": "Non è possibile modificare la password di un account di un altro utente.",
    "CONFIRM_ACCOUNT_MSG": "Non sarà possibile effettuare il login con il nuovo account fino al click sul link di conferma nell'email che sarà inviata all'indirizzo inserito.",
    "LOGOUT_MSG": "L'utente dovrà effettuare il logout dal vecchio account, in caso contrario non potrà utilizzare il nuovo account e gli verrà mostrato il wizard di registrazione.",
    "INVALID_EMAIL_MSG": "Se si inserisce un indirizzo email a cui non si ha accesso, l'account diventerà inaccessibile e sarà necessario rivolgersi all'assistenza."
  },
  "crud": {
    "DELETED": "Cancellato!",
    "DELETED_INFO": "L'elemento è stato eliminato.",
    "error": {
      "11000": "Impossibile completare l'operazione. Elemento già presente"
    },
    "SAVED": "Salvataggio avvenuto con successo",
    "VALIDATION_ERROR": "I dati inseriti contengono errori.",
    "VALIDATION_ERROR_VERIFY": "Verifica prima di salvare."
  },
  "customers": {
    "ACTIVE": "Committente attivo",
    "ADD_CONTRIBUTOR_BLACKLIST": "Aggiungi collaboratore alla blacklist",
    "ADD_CONTRIBUTOR_BLACKLIST_MSG": "Aggiungere il collaboratore alla blacklist?",
    "ADD_CONTRIBUTOR_BLACKLIST_SUCC": "Collaboratore aggiunto alla blacklist",
    "ASK_ACTIVE": "Vuoi attivare il committente?",
    "ASK_DEACTIVE": "Vuoi disattivare il committente?",
    "ASK_EXISTING_HEADQUARTER": "Proseguendo, la sede inserita dall'utente sarà sovrascritta con una già esistente.",
    "ASK_NEW_HEADQUARTER": "Proseguendo, sarà creata una nuova sede dell'azienda.",
    "ASSOCIATE_CUSTOMER": "Associa cliente",
    "ASSOCIATE_EXISTING_HEADQUARTER": "Associa l'utente alla sede selezionata",
    "ASSOCIATE_NEW_HEADQUARTER": "Crea una nuova sede e associa l'utente",
    "CHIEF": "Committente capo",
    "COMPANY_HEADQUARTERS": "Queste sono le sedi dell'azienda già salvate",
    "CONTRIBUTORS_BLACKLIST": "Blacklist collaboratori",
    "CREATE_CUSTOMER": "Crea un nuovo committente",
    "CUSTOMER": "Committente",
    "CUSTOMERS": "Committenti",
    "DEL_CONTR_MSG": "Rimuovere il collaboratore?",
    "DEL_CONTR_SUCC": "Collaboratore rimosso",
    "DEL_MSG": "Eliminare il committente?",
    "DISABLE_MAIL_BUT_OFFER": "Invia solo email di offerta",
    "HEADQUARTER_ACTION": "Vuoi associarlo a una di queste sedi ignorando i dati inseriti o vuoi creare una nuova sede a partire da essi?",
    "HEADQUARTER_NOT_REGISTERED": "si è registrato con questa sede operativa, ma l'associazione deve essere confermata",
    "NOT_ACTIVE": "Committente non attivo",
    "NO_HEADQUARTER": "Nessuna sede selezionata"
  },
  "dashboard": {
    "DATE_RANGE": "Modifica intervallo",
    "DAY": "Giorno",
    "LAST_30_DAYS_SUBTITLE": "Preventivi e ordini negli ultimi trenta giorni. Avvicina il puntatore del mouse al grafico per maggiori dettagli.",
    "LAST_30_DAYS_TITLE": "Ultimi 30 giorni"
  },
  "dialog": {
    "DELETE_ASK": "Vuoi eliminare l'elemento selezionato? Questa operazione non potrà essere annullata"
  },
  "due_diligence": {
    "SUBTITLE": "I nostri partner da tutto il mondo (Language Service Provider, agenzie di web marketing, agenzie grafiche, ecc.) sono un elemento cruciale del nostro successo ed è per questo che attuiamo un processo di selezione e valutazione basato sui rigorosi standard di Qualità ISO9001 e 17100. Se desideri avviare una collaborazione con Landoor, sei pregato pertanto di rispondere alle domande del seguente questionario: le tue risposte saranno sottoposte alla valutazione del nostro reparto di Vendor Management finalizzata all'avvio dell'iter di certificazione.",
    "TITLE": "Questionario aziendale",
    "production": {
      "QUESTIONS": {
        "Q_1": "Come vi assicurate di avere abbastanza risorse a disposizione per ogni progetto?",
        "Q_2": "Utilizzate strumenti per il capacity planning? Se sì, quali?",
        "Q_3": "Vi avvalete di Subject Matter Expert? Se sì, indicate con precisione il numero di SME a vostra disposizione per ogni settore e la relativa capacità giornaliera (NB.: per “Subject Matter Expert” si intende un soggetto con esperienza e conoscenze approfondite in qualsiasi altro campo diverso dalla traduzione. Ad esempio, un militare in pensione potrebbe essere di supporto ad una localizzazione in campo militare.)",
        "Q_4": "Descrivete il processo e i requisiti per il reclutamento e la selezione di Subject Matter Expert.",
        "Q_5": "Descrivete l’intero processo di un tipico progetto di localizzazione. I progetti più ampi e complessi vengono gestiti in maniera diversa rispetto a quelli più piccoli? Se sì, in che modo?"
      },
      "TITLE": "Produzione, capacità e competenze"
    },
    "quality": {
      "QUESTIONS": {
        "Q_1": "Descrivete in maniera dettagliata il vostro processo di controllo qualità.",
        "Q_2": "Completate una checklist prima della consegna della traduzione? Cosa prevede?",
        "Q_3": "Come garantite che le vostre traduzioni soddisfino gli standard richiesti?",
        "Q_4": "Come cercate di evitare che vi siano errori o sviste nei testi, come gli errori di battitura?",
        "Q_5": "Come vi assicurate che all’intero dei vostri team vengano condivise conoscenze e competenze?",
        "Q_6": "In che modo vengono capitalizzati i feedback del cliente?",
        "Q_7": "Nella vostra azienda è presente un responsabile del controllo qualità per ogni combinazione linguistica?",
        "Q_8": "Quali sono i suoi compiti?",
        "Q_9": "Che tipo di controlli effettuate sui progetti gestiti esternamente all’azienda/in subappalto?",
        "Q_10": "Come garantite che vi sia coerenza nel testo in caso di grandi progetti gestiti da più di un traduttore?",
        "Q_11": "Avete esperienza per quanto riguarda la condivisione dei contenuti e delle TM? Se sì, di che tipo?",
        "Q_12": "Siete in grado di fornire servizi di transcreation or marketization? Se sì, specifica."
      },
      "TITLE": "Controllo qualità"
    },
    "story": {
      "QUESTIONS": {
        "Q_1": "Descrivete la storia della società",
        "Q_2": "Descrivete la struttura dell’azienda",
        "Q_3": "L’azienda o i suoi dirigenti sono mai stati coinvolti in fallimenti, contenziosi, fusioni o acquisizioni?",
        "Q_4": "Elencate i clienti più importanti dell’azienda, specificandone settore di attività, anno di inizio collaborazione, fatturato e servizi forniti.",
        "Q_5": "Quali sono stati i tre risultati aziendali più significativi dell’esercizio appena concluso?",
        "Q_6": "Quali sono i vostri tre obiettivi principali per l’esercizio in corso?",
        "Q_7": "Dov’è localizzata la produzione?",
        "Q_8": "Il settore sta attraversando un periodo di rapido cambiamento. Anziché procedere per progetti di grandi dimensioni con tempistiche medio-lunghe, oggi sempre più clienti richiedono traduzioni di documenti medio-piccoli con tempistiche brevi.  Come vi approcciate a questa situazione? Sareste disposti a far rientrare tutti i progetti effettuati in un unico ordine cumulativo a fine mese?"
      },
      "TITLE": "Storia aziendale"
    },
    "vendor": {
      "QUESTIONS": {
        "Q_1": "Come vengono gestite le risorse esterne all’azienda (fornitori - collaboratori freelance)? Avete un Vendor Manager?",
        "Q_2": "Qual è il processo di qualifica di tali risorse? Descrivetelo nel dettaglio.",
        "Q_3": "Quali sono i requisiti minimi per la qualifica di ogni tipologia di risorse?",
        "Q_4": "Effettuate follow-up regolari con i collaboratori freelance?",
        "Q_5": "Con quante risorse collaborate regolarmente?",
        "Q_6": "Quali combinazioni linguistiche siete in grado di gestire internamente all’azienda grazie al vostro team in-house?"
      },
      "TITLE": "Vendor management"
    }
  },
  "error": {
    "403_MSG": "Non hai l'autorizzazione per vedere questa risorsa",
    "404_MSG": "Non abbiamo trovato quello che stai cercando",
    "DONT_WARRY": "Non preoccuparti, sistemeremo presto",
    "RECEIVE_ERR": "Errore ricezione dati dal server",
    "SERVER_NOT_RESPOND": "Il server non risponde",
    "SOMETHING_WRONG": "C'è qualcosa che non va",
    "TRY_LATER": "Riprova tra un attimo",
    "TASK_REQUIRES_FILE_ERROR": "Per contrassegnare questo task come eseguito, devi caricare almeno un file",
  },
  "file": {
    "ADDITIONALS": "File aggiuntivi",
    "ADMIN": "Documenti amministrativi",
    "CREATED_AT": "Data caricamento",
    "DESCRIPTION": "Descrizione",
    "DOWNLOAD_POPUP_MSG": "Download avviato.\nSe non riesci a scaricare il file, verifica che il blocco popup del tuo browser non sia attivo.",
    "DOWNLOAD_ALL": "Scarica tutti",
    "ERROR_DIMENSION": "L'allegato è troppo grande. Massimo ",
    "EXTENSION": "Estensione",
    "FILES": "FILE",
    "FILE_ALREADY_EXISTS1": "Il file ",
    "FILE_ALREADY_EXISTS2": " esiste già. Vuoi sovrascriverlo? Continuare?",
    "INSERT_METADATA_FILE": "Seleziona macrosettore, settore e tipo documento",
    "LOAD_COMPLETE": "Caricamento file completato",
    "MESSAGE_DELETE": "Vuoi eliminare il file definitivamente?",
    "NO_FILES": "Nessun file caricato",
    "NO_FILES_ERR": "È richiesto il caricamento di almeno un file",
    "REFERENCE": "Documenti di Riferimento",
    "SOURCE": "File sorgente",
    "TARGET_FILES": "File lavorati",
    "UPLOAD_FILES": "Carica file"
  },
  "form": {
    "ADDRESS": "Indirizzo",
    "ADDRESS_RESIDENCE": "Indirizzo residenza",
    "AGREEMENTS": "Consensi",
    "BIRTH_TITLE": "Data e luogo di nascita",
    "BLACKLIST": "Blacklist",
    "CITY": "Città",
    "CIVIC_NUMBER": "Numero civico",
    "CODE": "Codice",
    "CODE_SUPPLIER": "Codice fornitore/coll.",
    "COMPANY_NAME": "Ragione sociale",
    "COMPANY_NAME_SHORT": "R. sociale",
    "COMPANY_NAME2": "Ragione sociale 2",
    "COMPANY_VAT": "Partita IVA",
    "COMPANY_VAT_NAME": "Partita IVA o ragione sociale",
    "COMPANY_VAT_SHORT": "P.IVA",
    "CONTACTS": "Contatti",
    "CONTACTS_COMPANY": "Recapiti aziendali",
    "CONTRIBUTOR_TYPE": "Tipo collaboratore",
    "CONTRIBUTOR_U_TYPE": "Tipo utente",
    "DATE_ACQ": "Data acquisizione",
    "DATE_OF_ESTABLISHMENT": "Data e luogo di fondazione",
    "DELIVERY_DATE": "Data consegna richiesta",
    "DELIVERY_DATE_UND": "non definita",
    "DELIVERY_TIME": "Orario consegna richiesta",
    "DEPARTMENT": "Divisione / reparto",
    "DESCRIPTION": "Descrizione",
    "DISTRICT": "Provincia",
    "DISTRICT_STATE": "Provincia/Stato",
    "FOR_PRICE_RANGE": "Per fascia di prezzo",
    "GENDER": "Sesso",
    "GENERAL_INFO": "Info generali",
    "HANDLE_AGREEMENTS": "Gestisci i tuoi consensi",
    "INSERT_INFO": "Seleziona un elemento e premi Inserisci",
    "KCODE": "Codice personale",
    "LAST_MODIFIED": "Ultima modifica",
    "LEGAL_ADDRESS": "Sede legale",
    "LINKEDIN": "Profilo Linkedin",
    "NAME": "Nome",
    "NATION": "Nazione",
    "NATION_CODE": "Sigla paese",
    "NOTE": "Note",
    "N_ORDER": "Numero Ordine",
    "N_ORDER_PO": "Numero PO",
    "OPERATIVE_ADDRESS": "Sede operativa",
    "OPERATIVE_ADDRESSES": "Sedi operative",
    "PHONE": "Telefono",
    "PHYSICAL_PERSON": "Persona fisica",
    "PO_DATE": "Data PO",
    "PRICE_RANGE": "Fascia di prezzo",
    "PRICE_RANGES": "Fasce di prezzo",
    "PROFILE": "Profilo",
    "REFERENT": "Referente",
    "REFERENT1": "Referente 1",
    "REFERENT2": "Referente 2",
    "REGISTRATION_DATE": "Data di registrazione",
    "REGISTRATION_DATE_SHORT": "Reg.",
    "REQUIRED_INFO": "I campi con asterisco sono obbligatori",
    "REQUIRE_PDF": "Richiedi PDF Ordine",
    "REQUIRE_QUOTE": "Obbligo preventivo",
    "SELECT_ALL_LANGUAGES_HERE_MSG": "Selezionare tutte le lingue target. Verrà creato un nuovo progetto per ogni lingua.",
    "SELECT_UNIT_PLACEHOLDER": "Seleziona un'unità di misura",
    "SETTINGS": "Impostazioni",
    "SIZE": "Dimensione",
    "SKYPE_NAME": "Nome Skype",
    "STATE": "Stato",
    "SURNAME": "Cognome",
    "TAX_CODE": "Codice fiscale",
    "TAX_CODE_SHORT": "C.F.",
    "TITLE": "Titolo",
    "TITLE_OR_CODE": "Titolo o Codice",
    "TITLE_OR_CODE_PHOLDER": "Inserisci qui il titolo o codice ordine",
    "VAT": "Partita IVA",
    "VAT_PREFIX": "Partita IVA (Sigla paese/numero)",
    "WEB_SITE": "Sito Web",
    "ZIP": "CAP",
    "ORDERLABEL": "Sigla per commessa",
    "FRAMEWORK_AGREEMENT_NUMBER": "Numero accordo quadro",
    "FRAMEWORK_AGREEMENT_DATE": "Data scadenza accordo"
  },
  "gender": {
    "FEMALE": "Femmina",
    "MALE": "Maschio"
  },
  "header": {
    "CACHE_DELETED": "La cache è stata svuotata",
    "CLEAR_CACHE": "Cancella la cache",
    "FULLSCREEN": "Schermo intero",
    "HIDE_SHOW_MENU": "Mostra/nascondi menù",
    "OFFLINE": "Sei offline",
    "OFFLINE_INFO": "Alcune funzioni potrebbero non essere disponibili.",
    "ONLINE": "Sei online"
  },
  "zoho": {
    "BULK_SYNC": "Sincronizza tutto con Zoho",
    "BULK_SYNC_MSG": "Vuoi sincronizzare tutti gli elementi con Zoho? L'operazione richiederà alcuni minuti.",
    "BULK_SYNC_SUCCESS": "La sincronizzazione è stata avviata. Controlla la data dell'ultima sincronizzazione ricaricando la pagina tra alcuni minuti.",
    "LAST_BULK_SYNC": "Ultima sincronizzazione con Zoho:",
    "SYNC": "Sincronizza con Zoho",
    "SYNC_ERROR": "Sincronizzazione con Zoho non riuscita",
    "SYNC_MSG": "Vuoi sincronizzare l'elemento con Zoho?",
    "SYNC_SUCCESS": "Sincronizzazione con Zoho completata",
    "SYNC_AT": "Sinc. Zoho"
  },
  "INVOICE_TYPES": {
    "fattura": "Fattura",
    "notaCredito": "Nota di credito",
    "rebate": "Rebate",
    "acconto": "Acconto/Saldo",
  },
  "jobs": {
    "CEO": "Amministratore delegato",
    "EMPLOYEE": "Impiegato",
    "MANAGER": "Manager",
    "PM": "Project Manager"
  },
  "langs": {
    "EN": "Inglese",
    "IT": "Italiano",
    "NATIVE1": "Lingua Madre 1",
    "NATIVE2": "Lingua Madre 2",
    "SOURCE": "Lingua sorgente",
    "TARGET": "Lingue target",
    "TARGET_SING": "Lingua target",
  },
  "library": {
    "ABBR_EN": "Abbreviazione en",
    "ABBR_IT": "Abbreviazione it",
    "ACQUISITION_SOURCE": "Fonte di acquisizione",
    "ACQUISITION_SOURCES": "Fonti di acquisizione",
    "ACTIONS": "Azioni",
    "ACTIVE": "Attivo",
    "ADD_LIBRARY": "Inserisci un nuovo elemento nella libreria",
    "CODE": "Codice",
    "CODE_SOA": "Codice SOA",
    "DAYS": "Giorni",
    "DEADLINE_TYPE": "Tipo di scadenza",
    "DEFAULT": "Default",
    "DESC_EN": "Descrizione en",
    "DESC_IT": "Descrizione it",
    "DESC_REQUIRED": "Richiesta descrizione",
    "DESTINATION_USE_DOCUMENT": "Destinazione uso documento",
    "DOCUMENT_TYPE": "Tipo",
    "EXTENSION": "Estensione",
    "FOREIGN": "Straniero",
    "GENERAL": "Generale",
    "GENERIC": "Generico",
    "ID_IVA_IPSOA": "Id Iva Ipsoa",
    "LANG_OPTIONAL": "Lingue opzionali",
    "LANG_REQUIRED": "Lingue richieste",
    "LANGS": "Lingue",
    "LIBRARIES": "Tabelle",
    "LIBRARY": "Tabella libreria",
    "NON_DEDUCIBLE": "Non deducibile",
    "NO_LAYOUT": "No Preimpag.",
    "NO_LIST": "Nessun listino",
    "OBSOLETE": "Obsoleto",
    "OTHER": "Altro",
    "PARENT": "Parent",
    "PDF_CREATE": "Crea un nuovo template pdf",
    "PDF_DETAIL": "Modifica template offerta",
    "PERCENT": "Percentuale",
    "PRICE_LIST": "Listino prezzi",
    "PRICES_LIST": "Listini prezzi",
    "PUBLIC": "Pubblico",
    "SECTOR": "Settore",
    "SELECT_PARENT": "Seleziona un parent",
    "SELECT_TABLE": "Seleziona una tabella",
    "SELECT_TASKS": "Seleziona i task",
    "SOFTWARE": "Software",
    "TASKS": "Task",
    "TASK_CREATE": "Crea un nuovo servizio",
    "TASK_DETAIL": "Modifica il task",
    "TGEST": "TEGest",
    "TGEST_WORK_CODE": "TEGest Work Code",
    "TYPE": "Tipo",
    "UNITS": "Unità di misura per questo servizio",
    "VALUE": "Valore",
    "VALUE_EN": "Nome en",
    "VALUE_IT": "Nome it",
    "WEIGHT": "Pesi",
    "multiselect": {
      "nothingSelected": "Nessuno",
      "reset": "Ripristina",
      "search": "Cerca...",
      "selectAll": "Seleziona tutto",
      "selectNone": "Deseleziona tutto"
    },
    "IS_TRANSLATION": "Traduzione"
  },
  "order": {
    "CANCEL_INVOICE": "Annulla fatturazione",
    "CANCEL_INVOICE_ERR": "Si è verificato un errore con l'annullamento della fatturazione",
    "CANCEL_INVOICE_MSG": "Vuoi annullare la fatturazione?",
    "CREATE_MESSAGE_DRAFT": "Vuoi creare un ordine in bozza?",
    "DELETE_REQUEST_CONFIRM": "Vuoi eliminare l'ordine?",
    "DETAILS": "Dettagli",
    "INVOICE_CONFIRM": "Conferma fattura proforma",
    "INVOICE_CONFIRM_MSG": "Inviare richiesta di conferma fattura proforma?",
    "INVOICE_CONFIRM_SUCC": "Richiesta di conferma fattura proforma inviata",
    "INVOICED_CONFIRM_SUCC": "Fatturazione avvenuta con successo",
    "INVOICED_CONFIRM_MSG": "Vuoi fatturare?",
    "INVOICE_ORDER": "Fattura ordine",
    "INVOICE_ORDER_ERR": "Sono presenti uno o più batch non completati",
    "INVOICE_ORDER_MSG": "Vuoi fatturare l'ordine?",
    "NONE": "Nessun ordine",
    "ORDER": "Ordine",
    "ORDERS": "Ordini",
    "ORDER_RECEIVED": "Ordine ricevuto",
    "OWN": "Miei ordini",
    "REQUIRE_QUOTE": "E' necessario l'invio di una richiesta di preventivo prima della creazione di un ordine. Si desidera creare una nuova richiesta di preventivo?",
    "SEND_REQUEST_CONFIRM": "Vuoi inviare l'ordine?",
    "SEND_REQUEST_PM_ASSIGNED_MSG": "Vuoi assegnare l'ordine al PM?",
    "SEND_REQUEST_PM_ASSIGNED_SUCCESS": "PM assegnato all'ordine correttamente.",
    "SENT_AT": "Data entrata ordine",
    "INVOICE_CONFIRMED_MSG": "Vuoi confermare i dati di fatturazione?",
    "INVOICE_CONFIRMED_SUCC": "Dati di fatturazione confermati con successo",
    "TO_BILL_MSG": "Vuoi contrassegnare l'ordine come \"da fatturare\"?",
    "TO_BILL_SUCC": "Ordine contrassegnato come \"da fatturare\"",
    "SHOW_DOCUMENTAL": "Visualizza documentale",
    "ORDER_STATUS_CHANGE": "Vuoi cambiare lo stato d'ordine?",
    "ORDER_STATUS_CHANGE_ERROR": "Impossibile cambiare stato dell'ordine.",
    "ACTUAL_DELIVERY_TIME_REQUIRED_ERROR": "Impostare la data di uscita effettiva nella tab \"Info generali\" e poi salvare.",
    "IS_NOT_CHILD": "L'ordine non è collegato a un altro ordine già fatturato.",
    "IS_NOT_PARENT": "L'ordine non è collegato ad altri ordini prefatturati.",
    "LINK_TO_PARENT": "Collega a ordine come prefatturato",
    "LINK_TO_PARENT_SHORT": "Collega",
    "REMOVE_LINK_TO_PARENT": "Rimuovi collegamento a ordine già fatturato",
    "LINK_TO_PARENT_ALREADY_LINKED_ERROR": "Non è possibile collegare un ordine a un altro ordine già collegato come prefatturato.",
    "LINK_TO_PARENT_EXPIRED_ERROR": "Il pacchetto selezionato è scaduto",
    "LINK_TO_PARENT_SAME_ORDER_ERROR": "Non è possibile collegare un ordine a se stesso",
    "LINK_TO_PARENT_NOT_INVOICED_ERROR": "Non è possibile collegare un ordine a un altro ordine prefatturato oppure non fatturato",
    "LINK_TO_PARENT_QUOTE_ERROR": "Non è possibile collegare un ordine a un preventivo",
    "ORDER_IS_CHILD": "L'ordine è incluso (prefatturato) in:",
    "ORDER_IS_PARENT": "L'ordine include i seguenti prefatturati:",
    "UNLINK_FROM_PARENT_SHORT": "Scollega",
    "UNLINK_FROM_PARENT_MSG": "Vuoi scollegare l'ordine?",
    "GO_TO_LINKED_ORDER": "Vai all'ordine",
    "LINK_TO_PARENT_TOTAL_INVOICED": "Totale netto fatturato",
    "LINK_TO_PARENT_TOTAL_PRE_INVOICED": "Totale netto ordini prefatturati",
    "LINK_TO_PARENT_TOTAL_REMAINING": "Totale netto residuo",
    "LINKED_TO": "Collegato a",
    "LINKED_TO_CODE": "Collegato a Codice Ordine",
    "LINKED_TO_SHORT": "Colleg.",
    "ORDER_TYPE": "Tipologia ordine",
    "ORDER_TYPE_NORMAL": "Ordine",
    "ORDER_TYPE_PACKAGE": "Pacchetto",
    "EXPORT_ALL_COLUMNS": "Per vedere tutte le colonne, effettua un'esportazione in CSV usando il pulsante in alto a destra."
  },
  "password": {
    "NO_PASSWORD": "Nessuna password inserita",
    "PASSWORD_STR1": "Password non sicura",
    "PASSWORD_STR2": "Password poco sicura",
    "PASSWORD_STR3": "Password abbastanza sicura",
    "PASSWORD_STR4": "Password sicura"
  },
  "pdf": {
    "BODY": "Corpo",
    "CLOSING": "Chiusura",
    "CLOSING_PLACEHOLDER": "Inserisci il testo di chiusura",
    "COMPANY_TITLE": "Spett.",
    "CUSTOMER_TITLE": "Att.: Gent.",
    "error": {
      "NO_DEFAULT_TEMPLATE_FOUND": "Non è stato trovato un template PDF di default"
    },
    "DOWNLOAD_OFFER_PREVIEW": "Scarica anteprima in PDF",
    "HEADER": "Intestazione",
    "HEADER_PLACEHOLDER": "Inserisci l'intestazione",
    "OFFER_FOR": "Offerta per ",
    "OPENING": "Apertura",
    "OPENING_TEXT": "Testo di apertura",
    "OPENING_TEXT_PLACEHOLDER": "Inserisci il testo di apertura",
    "SUBJECT": "Oggetto",
    "SUBJECT_PLACEHOLDER": "Inserisci l'oggetto",
    "TEXT": "Testo",
    "TEXT_PLACEHOLDER": "Inserisci il testo"
  },
  "quote": {
    "ADD_PROJECT": "Aggiungi progetto",
    "APPROVE": "Approva",
    "APPROVE_ASK": "Approvare il preventivo?",
    "APPROVE_ASK_INFO": "Sarà inviato un ordine.",
    "APPROVE_ASK_MSG": "Premendo il tasto CONFERMA approvi il preventivo e le eventuali note presenti nel documento in formato PDF. Il preventivo diventerà quindi un ordine di lavoro esecutivo e sarà avviata la lavorazione.",
    "APPROVE_SUCCESS": "Ordine inviato",
    "ASK_APPROVAL": "Invia l'offerta al cliente",
    "ASK_APPROVAL_MSG": "Vuoi inviare l'offerta al cliente per chiederne l'approvazione?",
    "ASK_APPROVAL_SUCC": "Offerta inviata",
    "CANCEL": "Annulla preventivo",
    "CANCEL_MSG": "Annullare il preventivo?",
    "CANCEL_SUCCESS": "Preventivo annullato",
    "CANNOT_LOAD_FILE": "Se non è possibile caricare ora i documenti da lavorare, compilare i seguenti campi",
    "CANNOT_LOAD_FILE_WARNING": "Se non è possibile caricare ora i documenti da lavorare, selezionare una motivazione",
    "COMPANY": "Società",
    "CREATED_AT": "Data creazione preventivo",
    "CUSTOMER_COUNTRY": "Nazione cliente",
    "CUSTOMER_MACROSECTOR": "Macrosettore attività cliente",
    "CUSTOMER_SECTOR": "Settore attività cliente",
    "CUSTOMER_SOURCE": "Fonte acquisizione cliente",
    "AMOUNT_CONFIRM": "Conferma fattura proforma",
    "CONSIGNOR": "Committente",
    "CREATE_MESSAGE_DRAFT": "Vuoi creare un preventivo in bozza?",
    "DELETE_REQUEST_CONFIRM": "Vuoi eliminare il preventivo?",
    "DELETE_REQUEST_SUCCESS": "Preventivo eliminato correttamente",
    "DELIVERY_DATE": "Data consegna",
    "DELIVERY_TIME": "Ora consegna",
    "DELIVERY_DATE_EFFECTIVE": "Data chiusura ordine",
    "DETAILS": "Dettagli",
    "FILE_MODE_AFTER": "Li caricherò in seguito",
    "FILE_MODE_CANNOT": "Non posso inviarli",
    "FILE_MODE_PAPER": "Invierò i file in copia cartacea",
    "FILE_NOT_UPLOAD_DESC": "Causa non caricamento",
    "FILE_NOT_UPLOAD_INFO": "Info file non caricati",
    "FILTERS_APPLIED": "Filtri applicati",
    "GET_BILLING_LEGAL_DATA": "Recupera dati da sede legale",
    "GET_MANAGERS_DEFAULT": "RECUPERA MANAGER DEL CLIENTE",
    "INVOICE_DATE": "Data fattura",
    "INVOICE_NUMBER": "Numero fattura",
    "NONE": "Nessun preventivo",
    "ORDER_NUMBER": "Numero Ordine",
    "OWN": "Miei preventivi",
    "QUOTE": "Preventivo",
    "QUOTES": "Preventivi",
    "REJECT": "Rifiuta preventivo",
    "REJECT_MSG": "Rifiutare il preventivo?",
    "REJECT_SUCCESS": "Il preventivo è stato rifiutato",
    "REQUEST_RECEIVED": "Richiesta ricevuta",
    "REVISION": "Revisione",
    "REVISION_MSG": "Incrementare il numero di revisione?",
    "SEND_REQUEST_CONFIRM": "Vuoi inviare la richiesta di preventivo?",
    "SEND_REQUEST_CONFIRM_INFO": "I dati non saranno più modificabili",
    "SEND_REQUEST_CONFIRM_ASK": "Invia la richiesta di preventivo",
    "SEND_REQUEST_CONFIRM_SUCCESS": "Richiesta inviata correttamente",
    "SEND_REQUEST_PM_ASSIGNED": "Assegna al PM",
    "SEND_REQUEST_PM_ASSIGNED_MSG": "Vuoi assegnare il preventivo al PM?",
    "SEND_REQUEST_PM_ASSIGNED_SUCCESS": "PM assegnato al preventivo correttamente.",
    "SERVICE_DETAIL": "DETTAGLIO SERVIZIO",
    "SHOW_FULL_TABLE": "Mostra tutte le colonne della tabella",
    "START_DATE": "Data disponibilità",
    "START_TIME": "Ora disponibilità",
    "TOTAL_AMOUNT": "Importo ordine",
    "TOTAL_COSTS": "Costi ordine",
    "TOTAL_COSTS_PM": "Costi PM",
    "TOTAL_DIRECT_COSTS": "Costi diretti",
    "TOTAL_INDIRECT_COSTS": "Costi indiretti",
    "TOTAL_INVOICE": "Importo fatturato",
    "TOTAL_PRJ": "Totale progetti",
    "TOTAL_GROSS": "Utile lordo",
    "TOTAL_PROFIT": "Utile",
    "TOTAL_SERV": "Totale servizi",
    "PROFIT_PERCENTAGE": "Margine"
  },
  "ratings": {
    "EXCELLENT": "Ottimo",
    "GOOD": "Buono",
    "INSUFFICIENT": "Insufficiente",
    "SUFFICIENT": "Sufficiente"
  },
  "roles": {
    "ACCOUNT_MANAGER": "Sales Manager",
    "ADMIN_MANAGER": "Account Manager",
    "BUSINESS_MANAGER": "Business manager",
    "MARKETING_MANAGER": "Marketing manager",
    "PROJECT_MANAGER": "Project manager",
    "PROJECT_MANAGER_BACKUP": "Project manager backup",
    "SALES_MANAGER": "Sales manager",
    "superadmin": "Amministratore/Superuser",
    "pm_s": "Project Manager senior",
    "pm_j": "Project Manager junior",
    "am": "Sales Manager",
    "admin": "Amministrazione",
    "cf": "Contabilità fornitori",
    "cc": "Contabilità clienti",
    "mm": "Marketing Manager",
    "dtp_s": "DTP senior",
    "dtp_j": "DTP junior",
    "hrm": "HR Manager",
    "vm": "Vendor Manager",
    "customer": "Cliente",
    "contributor": "Collaboratore"
  },
  "service": {
    "SERVICES": "Servizi",
    "TARGET_SAME_AS_SOURCE_ERR": "Lingua sorgente e lingua target devono essere diverse"
  },
  "service_detail": {
    "ADD_SRV": "Aggiungi servizio",
    "ASK_DELETE": "Vuoi eliminare il servizio?",
    "ASSER_TYPE": "Tipo asseverazione",
    "AUDIO_TRANSCR": "Trascrizione da audio",
    "BOOKBINDING": "Rilegatura",
    "BW": "Bianco e nero",
    "CAB": "Cabina insonorizzata",
    "CHUCHO": "Chuchotage",
    "COIL": "A spirale",
    "COLOR": "Colore",
    "CONSEC": "Consecutiva",
    "CONTRACT": "Contrattualizz. curatori",
    "COPIES_NUM": "Numero di copie",
    "CREATE_FILE_LANG": "Lingua file da creare",
    "CUR_SELECTION": "Selezione curatori",
    "CUSTOMER": "Cliente",
    "CUST_GEAR": "Impianto del cliente o della location",
    "DAYS_NUM": "N° giornate",
    "DELIVERY_FILE_FORMAT": "Formato di consegna",
    "DEST_COUNTRY": "Paese di destinazione",
    "DEST_FILE_FORMAT": "Formato file destinazione",
    "DIGITAL": "Digitale",
    "EDIT_SRV": "Modifica servizio",
    "EFFECTS": "Eventuali effetti",
    "FAIR": "Fiera",
    "FEMALE_SPKR_NUM": "N. speaker di sesso femminile",
    "FILE_PR_DOC": "Da documento digitale ",
    "FINAL_SUPPORT": "Supporto finale",
    "FORMAT": "Formato",
    "FROM_COPY": "Da copia conforme",
    "FROM_ORIGINAL": "Da originale",
    "GEAR_TYPE": "Tipologie impianti",
    "HOURS_PER_DAY": "N° ore/giornata",
    "INS_HERE": "Inserire qui",
    "INTER_TYPE": "Tipo interpretariato",
    "LANG_BI": "Interpretariato a due vie (possibilità di invertire source e target)",
    "LEN": "Durata",
    "LOCATION": "Location/luoghi degli incontri",
    "MALE_SPKR_NUM": "N. speaker di sesso maschile",
    "MANAGEMENT": "Gestione curatori",
    "MOV_TYPE": "Modalità di spostamento tra i luoghi degli incontri",
    "NEGOT": "Trattativa",
    "NET": "Filo refe",
    "NO": "No",
    "NONE": "Nessuna",
    "OFFSET": "Offset",
    "ONE": "1",
    "ORG_BY": "Organizzazione a cura di",
    "ORIG_FILE_LANGUAGE": "Lingua file originale",
    "ORIG_SUPPORT": "Supporto originale",
    "PAPERBACK": "Brossura fresata",
    "PAPER_WEIGHT": "Grammatura carta",
    "PEOPLE_NUM": "N° persone che necessitano di traduzione per singola sessione",
    "PRINT_FILE_FORMAT": "Formato file da stampare",
    "PRINT_TYPE": "Tipo di stampa",
    "PUB_HOUSE": "Casa editrice",
    "QUAD": "Quadricromia",
    "RECEIVE_FILE_LANG": "Lingua file da ricevere",
    "ROOM_AMP": "Amplificazione sala",
    "SELECT_MULTIPLE_FORMAT": "Seleziona uno più formati",
    "SELECT_MULTIPLE_LANG": "Seleziona una o più lingue",
    "SELECT_SINGLE_COUNTRY": "Seleziona un Paese",
    "SELECT_SINGLE_FORMAT": "Seleziona un formato",
    "SELECT_SINGLE_LANG": "Seleziona una lingua",
    "SIMUL": "Simultanea",
    "SOURCE_FILE_FORMAT": "Formato file sorgente",
    "SRV_DET": "Dettaglio servizio",
    "STAPLE": "Spillata",
    "SUBJECT": "Argomento",
    "SUB_POSITION": "Posizione sottotitoli",
    "TELECONF": "Teleconferenza",
    "TIME_EG": "es: 1:30:00",
    "TRANSEDIT": "Transedit",
    "TWO": "2",
    "YES": "Sì"
  },
  "settings": {
    "BANK": "Banca",
    "BANKS": "Banche",
    "BANK_AGENCY": "Filiale",
    "BUSINESS_CONSULTANT": "Commercialista",
    "DOC_SETTINGS": "Impostazioni Documenti",
    "HIDE_DEADLINES": "Nascondi scadenze",
    "MAX_LENGTH": "Massimo 100 caratteri",
    "MAX_ROW_LENGTH": "massimo 100 caratteri per righa",
    "SETTINGS": "Impostazioni",
    "SHOW_NOTES": "Mostra note",
    "SHOW_PRIVACY": "Mostra privacy"
  },
  "signup": {
    "ACCEPT_TERMS": "Accetto termini e condizioni d'uso",
    "ACCEPT_SLA": "Accetto Service Level Agreement",
    "ADDRESS_SELECT": "Scegli dalla lista",
    "COMPANY_NOT_FOUND": "Non è stata trovata l'azienda che cerchi. Premi Avanti per crearne una nuova oppure cerca ancora.",
    "COMPILE_INFO": "Compila tutti i campi e premi avanti",
    "COMPLETE_YOUR_ACCOUNT": "Completa il tuo account",
    "COMPLIMENTS": "Complimenti",
    "CREATE_ADDRESS": "Inserisci l'indirizzo della tua sede operativa",
    "ERROR_TYPE_CONTRIBUTOR": "Al momento non è possibile registrarsi come utente Collaboratore",
    "FINISH": "Hai completato la procedura di registrazione.",
    "FINISH_INFO_1": "Un amministratore verificherà i tuoi dati.",
    "FINISH_INFO_2": "Riceverai un'email quando il tuo account sarà attivo.",
    "LANG": "Lingua di visualizzazione",
    "LINK_TO_COMPANY": "Registra o trova la tua azienda",
    "NEW_ADDRESS": "Crea un nuovo indirizzo",
    "NEW_COMPANY": "Crea una nuova azienda",
    "NO_ADDRESS": "Nessun indirizzo trovato",
    "NO_CV_ERR": "È obbligatorio il caricamento del curriculum",
    "SEARCH_VAT": "Inserisci la partita iva",
    "SEARCH_VAT_NAME": "Cerca per partita iva o ragione sociale dell'azienda",
    "SECTORS": "Settori di specializzazione",
    "SELECT_COMPANY_TO_CONTINUE": "Seleziona l'azienda per continuare",
    "SELECT_OPERATIVE": "Scegli la tua sede operativa",
    "SEND_REQUEST": "Vuoi inviare la richiesta di registrazione?",
    "SIGNUP_ASK_CONFIRM_CONTRIBUTOR": "Vuoi registrarti come Collaboratore? Continuare?",
    "SIGNUP_ASK_CONFIRM_CUSTOMER": "Vuoi registrarti come Cliente? Continuare?",
    "SIGNUP_AS_CONTRIBUTOR": "Registrati come Collaboratore",
    "SIGNUP_AS_CUSTOMER": "Registrati come Cliente",
    "USER_SIGNUP": "Registrazione utente",
    "USER_SIGNUP_CONTRIBUTOR": "Registrazione collaboratore",
    "USER_SIGNUP_CUSTOMER": "Registrazione cliente",
    "USER_TYPE": "Tipo utente",
    "USER_TYPE_SELECT": "Scegli un tipo di utente",
    "WAITING": "IN ATTESA DI ATTIVAZIONE",
    "WAITING_INFO": "Un Project Manager sta analizzando il tuo profilo. Resta in attesa, riceverai un'email quando il tuo account sarà attivo.",
    "YOUR_DATA": "Inserisci i tuoi dati"
  },
  "status": {
    "all": "Tutti",
    "approved": "Approvato",
    "canceled": "Annullato",
    "confirmed": "Confermato",
    "dataconfirm": "Conferma dati fatt.",
    "dataconfirmed": "Dati fatt. confermati",
    "delivered": "Consegnato",
    "delivering": "In consegna",
    "done": "Eseguito",
    "draft": "In bozza",
    "expired": "Scaduti",
    "expiring": "In scadenza",
    "inprogress": "In lavorazione",
    "invoiced": "Fatturati",
    "notapproved": "Non approvato",
    "not_confirmed": "Non confermato",
    "pending": "Pending",
    "pm_assigned": "PM assegnato",
    "progress": "In lavorazione",
    "request_received": "Richiesta ricevuta",
    "request_sent": "Richiesta inviata",
    "standby": "Stand-by",
    "status": "Stato",
    "tobill": "Da fatturare",
    "waiting": "Da approvare",
    "billed": "Fatturato",
    "revisioned": "Revisionato",
    "test": "Test",
    "internal": "Landoor/Interno",
    "not_billable": "Annullato/Non fatturabile",
    "pre_invoiced": "Prefatturato"
  },
  "task": {
    "ADD_BATCH": "Aggiungi batch",
    "ADD_CONTRIBUTOR": "Aggiungi collaboratore",
    "ADD_CONTRIBUTOR_MSG": "Aggiungere il collaboratore?",
    "ADD_CONTRIBUTORS_MSG": "Aggiungere i collaboratori?",
    "ADD_CONTRIBUTOR_SUCC": "Collaboratore aggiunto",
    "BATCH_NAME": "Nome del batch",
    "BTN_BACK": "Indietro",
    "ADD_TASK": "Aggiungi task",
    "ALREADY_STARTED": "Il task successivo è già stato avviato",
    "AMOUNT": "Importo",
    "AMOUNT_SUM": "Riepilogo compensi",
    "CANNOT_START": "Non è possibile avviare un task di un progetto non avviato oppure preceduto da un altro task non completato.",
    "CONFIRMED": "Il collaboratore ha confermato la disponibilità",
    "CONTRIBUTOR_ACCEPT_CONDITIONS_MSG_1": "Le",
    "CONTRIBUTOR_ACCEPT_CONDITIONS_MSG_2": "CONDIZIONI GENERALI DI CONTRATTO",
    "CONTRIBUTOR_ACCEPT_CONDITIONS_MSG_3": "di Landoor Srl che definiscono gli impegni reciproci delle Parti con particolare riferimento - ma non limitatamente - alle clausole di riservatezza, privacy e non concorrenza.",
    "CONTRIBUTOR_ACCEPT_FOOTER_1": "Per confermare, clicca su ",
    "CONTRIBUTOR_ACCEPT_FOOTER_2": " e ",
    "CONTRIBUTOR_ACCEPT_LEGAL_MSG_1": "Il ",
    "CONTRIBUTOR_ACCEPT_LEGAL_MSG_2": "DISCIPLINARE RELATIVO ALLA SICUREZZA DELLE INFORMAZIONI",
    "CONTRIBUTOR_ACCEPT_LEGAL_MSG_3": "che definisce Regole di condotta ed obblighi dei collaboratori in relazione all’uso degli strumenti informatici, di Internet e della Posta Elettronica redatto anche ai sensi del provvedimento del Garante della Privacy (Deliberazione n. 13 del 1/3/2007 - pubblicata sulla GU n. 58 del 10 marzo 2007).",
    "CONTRIBUTOR_ACCEPT_MSG": "Premendo il tasto CONFERMA accetto il conferimento dell’incarico relativo al task, con il compenso indicato e alla scadenza richiesta. Con la presa in carico del lavoro confermo inoltre di aver letto e accettato integralmente i seguenti documenti:",
    "CONTRIBUTOR_ACCEPT_TITLE": "Conferma presa in carico",
    "CONTRIBUTOR_APPROVE_MSG": "Premendo il tasto APPROVA, dichiaro la mia disponibilità a prendere in carico il progetto. Attenderò conferma di incarico da parte del Project Manager prima di avviare il lavoro.",
    "CONTRIBUTOR_REJECT_MSG": "Rifiutare l'incarico?",
    "CONTRIBUTOR_NOTES": "Note collaboratore",
    "CUSTOMER_NOTES": "Note cliente",
    "COST_SUM": "Riepilogo costi",
    "DELETE_BATCH": "Elimina il batch",
    "DELETE_BATCH_MSG": "Eliminare il batch?",
    "DELETE_CONTRIBUTORS": "Disassocia collaboratori",
    "DELETE_CONTRIBUTOR_MSG": "Disassociare il collaboratore {{name}}?",
    "DELETE_CONTRIBUTORS_MSG": "Disassociare i collaboratori?",
    "DELETE_CONTRIBUTORS_SUCC": "Collaboratori disassociati",
    "DELETE_TASK": "Elimina task",
    "DELETE_TASK_MSG": "Eliminare il task?",
    "DELIVER_BATCH": "Marca come \"consegnato\"",
    "DELIVER_BATCH_MSG": "Cliccando su \"Conferma\" il PM conferma di aver eseguito un controllo di qualità del testo risultante dal flusso di lavoro precedente.",
    "DELIVER_BATCH_UNDO": "Marca il batch come \"non consegnato\"",
    "DELIVER_BATCH_UNDO_MSG": "Marcare il batch come \"non consegnato\"?",
    "DELIVERY": "Consegna",
    "DIRECT_COSTS": "Costi diretti",
    "DONE": "Eseguito",
    "DONE_MSG": "Contrassegnare il task come eseguito?",
    "DUPLICATE_BATCH": "Duplica il batch",
    "DUPLICATE_BATCH_MSG": "Duplicare il batch?",
    "DUPLICATE_SUCCESS": "Duplicazione completata",
    "EDIT_TRADOS_SUGGESTION": "Selezionare un'unità di misura con fasce di prezzo per caricare o modificare un log di Trados.",
    "EDIT_TRADOS_WARNING": "Le modifiche alle fasce Trados e alle fasce di prezzo avranno effetto solo dopo aver rimosso l'importo del task.",
    "ENTRY_DATE": "Data entrata",
    "ERR_SERV_TASK_UNIT": "Selezionare servizio, task e unità di misura",
    "EXPIRED": "Task scaduti",
    "EXPIRING": "Task in scadenza",
    "INDIRECT_COSTS": "Costi indiretti",
    "FAKE": "Fake",
    "HAMSTER": "Criceto",
    "HIDE_PROJECT_SOURCE_FILES_TO_CONTRIBUTOR": "Nascondi i file sorgenti del progetto al collaboratore",
    "HIDE_TARGET_FILES_TO_CUSTOMER": "Nascondi i file target del task al cliente",
    "INVOICE": "Fattura",
    "INVOICE_BATCH": "Fattura il batch",
    "INVOICE_BATCH_MSG": "Vuoi fatturare il batch?",
    "INVOICE_BATCH_UNDO": "Annulla fatturazione batch",
    "INVOICE_BATCH_UNDO_MSG": "Vuoi annullare la fatturazione del batch?",
    "INVOICE_INVALID_FILE_FORMAT": "La fattura deve essere in formato PDF.",
    "INVOICE_SELECTED_TASKS_ALREADY_INVOICED_ERR": "Almeno un task selezionato è già stato fatturato.",
    "INVOICE_SELECTED_TASKS_NO_INVOICED_TASK_ERR": "Seleziona almeno un task già fatturato.",
    "INVOICE_SELECTED_TASKS_NO_TASK_ERR": "Seleziona almeno un task.",
    "INVOICE_SELECTED_TASKS_NOT_DONE_ERR": "Non è possibile fatturare un task non completato.",
    "INVOICE_SELECTED_TASKS": "Fattura i task selezionati",
    "INVOICE_SELECTED_TASKS_UNDO": "Annulla fatturazione per i task selezionati",
    "INVOICE_SELECTED_TASKS_UNDO_MSG": "Vuoi annullare la fatturazione dei task selezionati?",
    "INVOICE_SELECTED_TASKS_MSG": "Vuoi contrassegnare i task selezionati come fatturati? Per procedere, è richiesto il caricamento di una fattura in formato PDF.",
    "LAST_30_DAYS_SUBTITLE": "Task negli ultimi trenta giorni",
    "NAME": "Nome",
    "NET_PROFIT": "Utile netto",
    "NOTIFIED": "Notifica avvio progetto inviata al collaboratore",
    "NOT_NOTIFIED": "Notifica avvio progetto non inviata",
    "NOT_CONFIRMED": "Il collaboratore non ha confermato la disponibilità",
    "NO_BATCH": "Nessun batch/workflow presente",
    "NO_TASK": "Nessun Task",
    "NO_CONTRIBUTORS": "Nessun collaboratore associato al task",
    "NOT_APPROVED": "Nessun collaboratore ha dato disponibilità per il task",
    "NONE_CONFIRMED": "Nessun collaboratore ha confermato la disponibilità per il task",
    "NOT_DONE": "Non eseguito",
    "OFFER": "Offerta",
    "PARAM": "Parametri",
    "PAYMENT": "Compenso",
    "PM_COSTS": "Costi PM",
    "PM_HAS_TO_START_PROJECT": "Il PM deve avviare il progetto",
    "PM_HAS_TO_START_TASK": "Il PM deve avviare il task",
    "PROFIT": "Utile",
    "PROFIT_PERCENTAGE": "Margine",
    "QUANT": "Quantità",
    "QUANT_SHORT": "Q.tà",
    "REFERENCE_PERIOD": "Periodo di riferimento",
    "REJECT_TITLE": "Perché non desideri accettare il task?",
    "REJECTION_LABEL": "Seleziona una o più motivazioni per continuare. Una volta confermata, non potrai annullare l'operazione.",
    "REJECTION_PLACEHOLDER": "Inserisci il motivo per cui non desideri approvare il task",
    "REJECTION_REASON_DATE": "Desidero un'altra data di consegna",
    "REJECTION_REASON_OTHER": "Altro",
    "REJECTION_REASON_PART": "Posso prenderne solo una parte",
    "REJECTION_REASON_PRICE": "Desidero un'altra tariffa",
    "REJECTION_REASON_UNAVAILABLE": "Non sono disponibile",
    "REJECTION_REASON_NOT_FIRST": "Un altro collaboratore ha accettato più velocemente",
    "RENAME_BATCH": "Rinomina il batch",
    "SET_FAKE_MSG": "Selezionando l'attributo Fake tutti i collaboratori saranno disassociati!",
    "SHOW_PROJECT_SOURCE_FILES_TO_CONTRIBUTOR": "Mostra i file sorgenti del progetto al collaboratore",
    "SHOW_TARGET_FILES_TO_CUSTOMER": "Mostra i file target del task al cliente",
    "START_MSG": "Confermi di voler comunicare al collaboratore di avviare la lavorazione del task?",
    "TASKS": "Task",
    "TASK_DETAIL": "Dettaglio Task",
    "TASK_INVOICED": "Task fatturato",
    "TASK_NAME": "Nome Task",
    "TASK_NAME_PLACEHOLDER": "Inserisci il nome del task",
    "TODO": "Da eseguire",
    "TOT_IMP": "Importo",
    "TRADOS_FOREIGN_CURRENCY_WARNING": "Le fasce Trados non sono supportate con valute diverse dall'Euro.",
    "UPLOAD_FILE": "Upload file",
    "SHOW_DOCUMENTAL": "Visualizza nel documentale"
  },
  "tribute": {
    "tribute_1040": "1040",
    "NO_RDA": "Non soggetto a RDA"
  },
  "uoms": {
    "DAY": "Giornata",
    "FOLDER": "Cartella",
    "FORFAIT": "Forfait",
    "HALF_DAY": "Mezza giornata",
    "HOUR": "Ora",
    "HOUR_TS": "Ore TS",
    "ROW": "Riga",
    "WORD": "Parola"
  },
  "user": {
    "CREDENTIALS": "Credenziali accesso",
    "EXPANDRIVE_PREFIX": "Prefisso unità ExpanDrive",
    "EXPANDRIVE_PREFIX_PLACEHOLDER": "Ad esempio: E",
    "FIRSTNAME": "Nome",
    "HAS_ACCOUNT": "Utente con account",
    "JOB_ROLE": "Ruolo in azienda",
    "LASTNAME": "Cognome",
    "LOGGED_USER": "Utente loggato",
    "NO_ACCOUNT": "Utente senza account",
    "NO_IMG_WARINING": "Non hai un'immagine del profilo",
    "PERMISSIONS": "Permessi",
    "PROFILE": "Profilo",
    "REPEAT_PASSWORD": "Ripeti password",
    "TITLE": "Titolo",
    "USER_ACCOUNT": "Account utente"
  },
  "workflow": {
    "CODE": "Codice",
    "24H_PROJECTS": "Progetti 24h",
    "AC_DEPARTMENT": "Reparto interessato",
    "AC_DESCRIPTION": "Descrizione AC",
    "AC_END_DATE": "Data fine",
    "AC_EXPIRATION_DATE": "Data scadenza", 
    "AC_OUTCOME": "Esito",
    "AC_START_DATE": "Data inizio",
    "ADD_BILL_ADDR": "Nuovo indirizzo di fatturazione",
    "ADD_BILL_COMP": "Aggiungi nuova intestazione",
    "ADD_BILL_COMP_VAT_ERR_1": "Non è possibile aggiungere una nuova intestazione con la Partita IVA inserita.",
    "ADD_BILL_COMP_VAT_ERR_2": "Puoi richiedere l'inserimento inviando un'email al Project Manager:",
    "ADD_BILL_COMP_VAT_MAIL_1": "Richiedo l'inserimento di una nuova intestazione fattura per",
    "ADD_BILL_COMP_VAT_MAIL_2": "Dati della nuova intestazione:",
    "ADD_PROJECTS": "Crea i progetti",
    "ALREADY_STARTED": "Già avviato",
    "ASK_SEND": "Invia",
    "ASK_SEND_INFO": "Cliccando su <b>Invia</b>, il preventivo non sarà più modificabile e verrà preso in carico da un project manager.",
    "BACK_TO_PROGRESS": "Riporta in lavorazione",
    "BILLING_NOTE": "Inserire qui informazioni aggiuntive sull’intestatario della fattura",
    "BILLING_OPTIONS": "Opzioni di fatturazione",
    "COMP_NAME": "Azienda",
    "COMPANY_COUNTER": "Progressivo cliente",
    "CONDITION": "Condizione",
    "CONDITIONS_OF_SUPPLY": "Condizioni di fornitura",
    "COPY_FIRST_PROJECT_CONF_MSG": "La configurazione del progetto sarà sovrascritta con la copia della configurazione del primo progetto della lista, ad eccezione dei collaboratori.",
    "COPY_FIRST_PROJECT_CONF_TOOLTIP": "Copia la configurazione del primo progetto della lista, ad eccezione dei collaboratori",
    "COPY_WORDS_NUMBER": "Copia numero di parole",
    "COPY_WORDS_NUMBER_MSG": "Verrà copiato il numero di parole e la data di consegna dal primo task di tipo \"Traduzione\" (codice task \"1\") con unità \"Parola\" (codice unità \"1\") del primo progetto in tutti gli altri task con uguale tipo e unità di misura",
    "COPY_WORDS_NUMBER_NO_COMPANY_ERROR": "Non è stato assegnato un cliente.",
    "COPY_WORDS_NUMBER_NO_PROJECTS_ERROR": "Non sono presenti progetti.",
    "COPY_WORDS_NUMBER_TASK_NOT_FOUND_ERROR": "Non è presente nel primo progetto un task di tipo \"Traduzione\" con unità \"Parola\".",
    "CORRECTIVE_ACTION": "Azione correttiva",
    "CREATE_AS_24H_PROJECT": "Crea come progetto 24H",
    "CUST_NAME": "Committente",
    "DOWNLOAD_ALL_TARGET_FILES": "Scarica tutti i file target",
    "DUPLICATE_AS_QUOTE": "Duplica come preventivo",
    "DUPLICATE_PROJECT_MSG": "Verrà creato un nuovo progetto per ogni lingua target. Nel progetto corrente verrà mantenuta la prima lingua target selezionata. Per il completamento degli altri progetti sarete riportati all'elenco dei progetti al completamento di quello corrente.",
    "DUPLICATE_WORKFLOW_MSG_1": "Verrà creato un nuovo ",
    "DUPLICATE_WORKFLOW_MSG_2": " utilizzando quello selezionato come modello. Sarà possibile effettuare modifiche.",
    "EXTRA_SERVICES": "Servizi aggiuntivi",
    "EXTRA_SERVICES_CLIENT": "Servizi aggiuntivi da offrire al cliente oltre al ciclo di lavoro previsto",
    "INCOMPLETE_LIST_ERR": "Listino incompleto per il task",
    "INVOICES": "Fatture",
    "INSERT_DISCOUNT_CODE_HERE": "(AGGIUNGI QUI EVENTUALI CODICI SCONTO)",
    "LANDOOR_ADMIN_FILES": "Documenti amministrativi non condivisi con il cliente:",
    "LOAD_TRADOS_LOG": "Carica log di Trados",
    "LOAD_TRADOS_LOG_ERR": "Il file non è valido",
    "LOAD_TRADOS_LOG_SUCC": "Caricamento file completato.",
    "NEW_WORKFLOW": "Nuova richiesta",
    "NEXT_STEP_DATE": "Data prossimo step",
    "NCAC": "NC/AC",
    "NC_CLOSING_DATE": "Data chiusura",
    "NC_COMPLAINT": "Non conformità/Reclamo",
    "NC_DEPARTMENT": "Reparto interessato",
    "NC_DESCRIPTION": "Descrizione reclamo/NC",
    "NC_EXPIRATION_DATE": "Data scadenza",
    "NC_OUTCOME": "Esito",
    "NC_REMEDY": "Rimedio",
    "NC_REPORTING_DATE": "Data segnalazione",
    "NC_ROOT_CAUSE": "Root cause",
    "NO_LIST_ERR": "Nessun listino per il task",
    "NO_PM_ASSOCIATED": "Nessun PM associato",
    "NO_PM_SELECTED": "Nessun PM selezionato nelle info del progetto.",
    "NO_TARGET_FILES_ERR": "Nessun file target da scaricare.",
    "NO_REVISION_17100_ERR": "Inserisci nel progetto almeno un task 'Revisione 17100'",
    "NOT_STARTED": "Non avviato",
    "OFFER_INCOMPLETE_ERROR": "Rivedi e salva i dati nella tab \"Offerta\" prima di richiedere l'approvazione al cliente",
    "OPTION_STANDARD": "Seleziona un servizio standard dalla tua lista",
    "OPTION_STANDARD_TOOLTIP": "Ti vengono proposti i soli servizi da te utilizzati più frequentemente",
    "OPTION_FULL": "Seleziona un servizio dalla lista completa",
    "OPTION_FULL_TOOLTIP": "Ti vengono proposti tutti i servizi offerti da Landoor",
    "OPTION_24H": "Seleziona un progetto standard (24h) dalla tua lista",
    "OPTION_24H_TOOLTIP": "Ti vengono proposti i progetti da noi preimpostati in automatico. Potrai solo togliere lingue e caricare i nuovi documenti.",
    "OPTION_TEMPLATE": "Seleziona un progetto template dalla tua lista",
    "OPTION_TEMPLATE_TOOLTIP": "Ti vengono proposti i progetti ricorrenti impostati dal project manager in funzione delle tue esigenze. Potrai apportare modifiche al progetto selezionato",
    "ORIGIN": "Origine ordine",
    "PROJECT_DETAIL": "Dettaglio progetto",
    "SAVE_AS_24H": "Salva come progetto 24H",
    "SAVE_AS_24H_MSG": "Salvare come progetto 24H?",
    "SAVE_AS_TEMPLATE": "Salva come progetto Template",
    "SAVE_AS_TEMPLATE_MSG": "Salvare come progetto Template?",
    "SAVE_CONTRIBUTOR_NOTES": "Salva note",
    "SAVE_CONTRIBUTOR_NOTES_ERR": "Non è stato possibile salvare le note. Riprova più tardi.",
    "SAVE_CONTRIBUTOR_NOTES_SUCC": "Modifiche salvate. Saranno visibili dal Project Manager.",
    "SAVE_DRAFT": "Salva in bozza",
    "SAVE_DRAFT_INFO": "Cliccare su <b>Salva in bozza</b> per completare successivamente la richiesta e inviarla in un secondo momento.",
    "SELECT_BILLING_COMPANY": "Scegli a chi intestare la fattura",
    "SELECT_BILLING_COMPANY_MESSAGE": "Da selezionare in caso di fatturazione a un’altra divisione, sede, ragione sociale, cliente finale con diversa partita iva rispetto a quella riportata sopra in \"Dati fatturazione\"",
    "SELECT_PROJECT": "Seleziona un progetto",
    "SEND_BILLING_DATA_REQUEST": "Invia richiesta conferma",
    "SEND_BILLING_DATA_REQUEST_ERR": "Non è al momento possibile inviare la richiesta. Riprova più tardi.",
    "SEND_BILLING_DATA_REQUEST_NO_EMAIL": "Questo cliente non ha un indirizzo email valido.",
    "SEND_BILLING_DATA_REQUEST_MSG": "Inviare al cliente una richiesta di conferma dei dati di fatturazione?",
    "SEND_BILLING_DATA_REQUEST_SUCC": "Richiesta inviata correttamente all'indirizzo ",
    "SEND_CONTRIBUTORS_MAILS": "Invia email ai collaboratori",
    "SEND_CONTRIBUTORS_MAILS_MSG": "Inviare le richieste di disponibilità ai collaboratori?",
    "SEND_OFFER_TO_CUSTOMER": "Invia l'offerta al cliente",
    "SEND_OFFER_TO_CUSTOMER_MSG": "Vuoi inviare l'offerta al cliente?",
    "SEND_OFFER_TO_CUSTOMER_SUCCESS": "Offerta inviata al cliente",
    "SEND_ORDER_REQUEST": "Vuoi inviare l'ordine?",
    "SEND_QUOTE_REQUEST": "Vuoi inviare la richiesta di preventivo?",
    "SEND_REQUEST_CONFIRM_SUCCESS": "Richiesta inviata correttamente",
    "SHARE_ALL_FILES": "Condividi file sorgente",
    "SHARE_ALL_FILES_MSG": "Condividere tutti i file sorgente dei progetti?",
    "SHARED_ADMIN_FILES": "Documenti amministrativi caricati dal cliente:",
    "STARTED": "Avviato",
    "START_PROJECT": "Avvia progetto",
    "START_PROJECT_MSG": "Avviare il progetto?",
    "START_TASK": "Avvia task",
    "SUMMARY_QUOTE": "Riepilogo preventivo",
    "TASK": "Task",
    "TEMPLATE_PROJECTS": "Progetti template",
    "VOICE": "Vocale",
    "OPEN_DOCUMENTAL": "Apri nel documentale",
    "OPEN_LAST_TARGET": "Apri la cartella target dell'ultimo task",
    "COPY_PATH": "Copia percorso FS",
    "COPY_PATH_SUCCESS": "Il percorso è stato copiato negli appunti",
    "COPY_PATH_ERROR": "Il browser in uso non supporta la copia del percorso",
    "PACKAGE_EXPIRES_AT": "Data scadenza pacchetto",
    "AI": "Lavorazione con AI",
    "EMAIL_DELIVER_ORDER_TITLE": "Email consegna ordine",
    "EMAIL_DELIVER_ORDER_DESCRIPTION": "Note aggiuntive per l'email di consegna ordine inviata al cliente. L'email verrà inviata automaticamente al completamento di tutti i task, oppure ne può essere forzato l'invio manualmente.",
    "EMAIL_DELIVER_ORDER_CUSTOMER_EMAIL": "L'email sarà inviata all'indirizzo del committente:",
    "EMAIL_DELIVER_ORDER_EMPTY_ERR": "Le note cliente sono vuote.",
    "EMAIL_DELIVER_ORDER_NO_EMAIL_ERR": "Il committente non ha un indirizzo email.",
    "EMAIL_DELIVER_ORDER_MSG": "Inviare al cliente l'email di consegna ordine, includendo le note?",
    "EMAIL_SEND_QUOTE_TITLE": "Email invio offerta",
    "EMAIL_SEND_QUOTE_DESCRIPTION": "Note aggiuntive per l'email di invio offerta. L'email verrà inviata all'invio dell'offerta, oppure ne può essere forzato l'invio manualmente.",
    "EMAIL_SEND_QUOTE_MSG": "Re-inviare al cliente l'email di invio offerta, includendo le note?",
    "EMAIL_SEND_QUOTE_STATUS_ERR": "È possibile forzare il re-invio solo se l'offerta è già stato inviata ed è in attesa di approvazione."
  },
  "VALIDATION": {
    "DATE": "Data non valida",
    "DECIMAL_SEP": "Decimali separati da ,",
    "INTEGER": "Inserire un numero intero",
    "MAX": "Valore minimo: {{max}}",
    "MAX_LENGTH": "Lunghezza massima {{length}} caratteri",
    "MIN": "Valore minimo: {{min}}",
    "MIN_LENGTH": "Lunghezza minima {{length}} caratteri",
    "NO_FILES_FOR_PROJECT": "Inserisci almeno un file sorgente per il progetto {{code}} oppure specifica la modalità futura di caricamento",
    "NO_PROJECTS_ERR": "Inserisci almeno un progetto",
    "NO_SERVICES_ERR": "Inserisci almeno un servizio per progetto",
    "NUMBER": "Inserire un numero valido",
    "NUMBER_LENGTH": "Inserire un numero di {{length}} cifre",
    "NUMBER_RANGE": "Inserire un numero da {{from}} a {{to}}",
    "PASSWORD_DIFFENTE": "Le password sono diverse",
    "PECSDI": "E' obbligatorio inserire la PEC o il codice SDI",
    "PREFIX_VAT": "Inserire 2 lettere maiuscole",
    "REQUIRED": "Campo obbligatorio",
    "REQUIRED_EMAIL": "Inserire un indirizzo email",
    "REQUIRED_PASSWORD": "Inserire la password",
    "TAX_CODE": "Inserire un codice fiscale valido",
    "URL": "Inserire un indirizzo web valido (solo lettere minuscole)",
    "VALID_AMOUNT": "Inserisci un importo valido",
    "VALID_EMAIL": "Inserire un indirizzo email valido",
    "ALPHANUMERIC": "Sono ammessi solo caratteri alfanumerici"
  },
  "quality": {
    "TITLE": "Controllo qualità",
    "QUESTIONS": {
      "Q_1": "Descrivete in maniera dettagliata il vostro processo di controllo qualità.",
      "Q_2": "Completate una checklist prima della consegna della traduzione? Cosa prevede?",
      "Q_3": "Come garantire che le vostre traduzioni soddisfino gli standard richiesti?",
      "Q_4": "Come cercate di evitare che vi siano errori o sviste nei testi, come gli errori di battitura?",
      "Q_5": "Come vi assicurate che all’intero dei vostri team vengano condivise conoscenze e competenze?",
      "Q_6": "In che modo vengono capitalizzati i feedback del cliente?",
      "Q_7": "Nella vostra azienda è presente un responsabile del controllo qualità per ogni combinazione linguistica?",
      "Q_8": "Quali sono i suoi compiti?",
      "Q_9": "Che tipo di controlli effettuate sui progetti gestiti esternamente all’azienda/in subappalto?",
      "Q_10": "Come garantite che vi sia coerenza nel testo in caso di grandi progetti gestiti da più di un traduttore?",
      "Q_11": "Avete esperienza per quanto riguarda la condivisione dei contenuti e delle TM? Se sì, di che tipo?",
      "Q_12": "Siete in grado di fornire servizi di transcreation or marketization? Se sì, specifica."
    }
  },
  "story": {
    "TITLE": "Storia aziendale",
    "QUESTIONS": {
      "Q_2": "Descrivete la struttura dell’azienda",
      "Q_3": "L’azienda o i suoi dirigenti sono mai stati coinvolti in fallimenti, contenziosi, fusioni o acquisizioni?",
      "Q_4": "Elencate i clienti più importanti dell’azienda, specificandone settore di attività, anno di inizio collaborazione, fatturato e servizi forniti.",
      "Q_5": "Quali sono stati i tre risultati aziendali più significativi dell’esercizio appena concluso?",
      "Q_6": "Quali sono i vostri tre obiettivi principali per l’esercizio in corso?",
      "Q_7": "Dov’è localizzata la produzione?",
      "Q_8": "Il settore sta attraversando un periodo di rapido cambiamento. Anziché procedere per progetti di grandi dimensioni con tempistiche medio-lunghe, oggi sempre più clienti richiedono traduzioni di documenti medio-piccoli con tempistiche brevi.  Come vi approcciate a questa situazione? Sareste disposti a far rientrare tutti i progetti effettuati in un unico ordine cumulativo a fine mese?"
    }
  },
  "vendor": {
    "TITLE": "Vendor management",
    "QUESTIONS": {
      "Q_1": "Come vengono gestite le risorse esterne all’azienda (fornitori - collaboratori freelance)? Avete un Vendor Manager?",
      "Q_2": "Qual è il processo di qualifica di tali risorse? Descrivetelo nel dettaglio.",
      "Q_3": "Quali sono i requisiti minimi per la qualifica di ogni tipologia di risorse?",
      "Q_4": "Effettuate follow-up regolari con i collaboratori freelance?",
      "Q_5": "Con quante risorse collaborate regolarmente?",
      "Q_6": "Quali combinazioni linguistiche siete in grado di gestire internamente all’azienda grazie al vostro team in-house?"
    }
  },
  "CURRENT_LIST_SINCE": "Listino corrente dal",
  "HISTORY": "Storico",
  "OLD_LIST_OF": "Listino del",
  "statistics": {
    "TITLE": "Statistiche"
  },
  "leads": {
    "TITLE": "Leads"
  },
  "agreements": {
    "1": "Avendo presa visione dell’informativa di LANDOOR S.r.l. unipersonale (per brevità “Landoor”) del 13.06.2018, pubblicata sul sito www.landoor.com, acconsento al trattamento dei miei dati personali da parte di Landoor ai sensi del nuovo Regolamento UE 2016/679 allo scopo di:",
    "1A": "1.a. rendere i servizi oggetto dei contratti in essere tra il sottoscritto e il Titolare del Trattamento;",
    "1B": "1.b. adempiere agli obblighi precontrattuali, contrattuali e fiscali derivanti da rapporti in essere con il sottoscritto;",
    "1C": "1.c. adempiere agli obblighi previsti dalla legge, da un regolamento, dalla normativa comunitaria o da un ordine dell’Autorità (come ad esempio in materia di antiriciclaggio);",
    "1D": "1.d. esercitare i diritti del Titolare, ad esempio il diritto di difesa in giudizio;",
    "2": "Acconsento al trattamento dei miei dati personali da parte di Landoor allo scopo di:",
    "2A": "2. ricevere via e-mail, posta e/o contatti telefonici le seguenti informazioni del Titolare: inviti a eventi, materiali formativi, newsletter, comunicazioni sui prodotti o servizi e rilevazione del grado di soddisfazione sulla qualità dei servizi;",
    "3": "Acconsento al trattamento dei miei dati personali da parte di Landoor allo scopo di:",
    "3A": "3. ricevere via e-mail, posta e/o contatti telefonici le seguenti informazioni di soggetti terzi (ad esempio, business partner): inviti a eventi, materiali formativi, newsletter, comunicazioni sui prodotti o servizi.",
    "4": "La durata del trattamento è quella specificata nella Informativa. Sono consapevole e informato del diritto di potere revocare in qualsiasi momento e per qualsiasi ragione il consenso a una o più delle sopracitate finalità utilizzando il “Modulo recesso dell’interessato” che mi sarà da voi inviato a semplice richiesta.",
    "CHECKED_BY_PM": "Dati forniti da committente su supporto esterno a Door24",
    "REQUIRED": "La spunta dei consensi è necessaria per procedere con la registrazione.",
    "WITHDRAWED": "Utente cancellato o con consenso ritirato",
    "WITHDRAWING": "Cancellazione"
  }
}